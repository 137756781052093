<form #form="ngForm" [formGroup]="formGroup" (ngSubmit)="onSubmit()" novalidate autocomplete="off" class="md:w-72">
  <div class="flex flex-col gap-4">
    <mat-form-field [floatLabel]="'always'" class="flex-auto gt-xs:pr-3">
      <mat-label>{{ buildKey("CHANGE_EMAIL_NEW_EMAIL") | translate }}</mat-label>

      <input
        matInput
        [formControlName]="FormKeys.NEW_EMAIL"
        class="form-control"
        type="email"
        [placeholder]="buildKey('EMAIL') | translate"
      />

      @if (controlHasError(FormKeys.NEW_EMAIL)) {
        <mat-error>
          @if (controlHasError(FormKeys.NEW_EMAIL, "required")) {
            {{ buildKey("ERROR_REQUIRED") | translate }}
          }
          @if (controlHasError(FormKeys.NEW_EMAIL, "pattern")) {
            {{ buildKey("ERROR_EMAIL") | translate }}
          }
        </mat-error>
      }
    </mat-form-field>

    @if (emailNotificationsAvailable) {
      <div style="margin-left: -0.55rem">
        <mat-checkbox color="primary" [formControlName]="FormKeys.UPDATE_ALERTING_EMAIL">
          {{ buildKey("UPDATE_EMAIL.ALSO_FOR_ALERTS") | translate }}
        </mat-checkbox>
      </div>
    }

    <mat-form-field [floatLabel]="'always'" class="flex-auto gt-xs:pr-3">
      <mat-label>{{ buildKey("PASSWORD") | translate }}</mat-label>

      <input
        matInput
        [formControlName]="FormKeys.PASSWORD"
        class="form-control"
        type="password"
        [placeholder]="buildKey('PASSWORD') | translate"
      />

      @if (controlHasError(FormKeys.PASSWORD)) {
        <mat-error>
          {{ buildKey("ERROR_PASSWORD_REQUIRED") | translate }}
        </mat-error>
      }
    </mat-form-field>

    <div>
      <button
        type="submit"
        mat-raised-button
        color="primary"
        class="w-full md:w-auto md:min-w-48"
        [disabled]="formGroup.invalid"
      >
        <mat-icon>check</mat-icon>
        {{ buildKey("CHANGE_EMAIL") | translate }}
      </button>
    </div>
  </div>
</form>
