import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Moment } from 'moment';
import { DateStringConversionService } from '@shared/consumption';
import { DateFormats, DateRange } from '@shared/common';
import { LanguageSelectionService } from '@shared/localization';
import { RoomsConsumptionValue } from '../models';

const ALLOWED_RANGE_MAX_YEARS = 3;
const DEFAULT_RANGE_MAX_YEARS = 1;

@Injectable({
  providedIn: 'root'
})
export class RoomsConsumptionPieDateService {
  public constructor(
    private dateStringConversion: DateStringConversionService,
    private languageSelection: LanguageSelectionService
  ) { }

  public getAvailableDataTimeRange(roomList: RoomsConsumptionValue[], currentDate: Moment) {
    const dateList = this.createDateList(roomList);

    const minDateInData = moment.min(dateList)
      .clone()
      .startOf('month');
    const maxDateInData = moment.max(dateList)
      .clone()
      .endOf('month');

    const allowedRangeEnd = currentDate.endOf('month');
    const allowedRangeStart = allowedRangeEnd.clone()
      .subtract(ALLOWED_RANGE_MAX_YEARS, 'year')
      .startOf('month');

    const availableDataTimeRangeStart = moment.max(allowedRangeStart, minDateInData);

    return { startDate: availableDataTimeRangeStart, endDate: maxDateInData };
  }

  public initialDateRange(availableDataTimeRange: DateRange<Moment>, desiredDates: { startDate: string; endDate: string }) {
    const initialStartDate = this.getDefaultDataTimeRange(availableDataTimeRange.startDate, availableDataTimeRange.endDate);

    return {
      startDate: this.resolveDate(
        desiredDates?.startDate,
        this.dateStringConversion.toString(initialStartDate.startDate),
        availableDataTimeRange),
      endDate: this.resolveDate(
        desiredDates?.endDate,
        this.dateStringConversion.toString(initialStartDate.endDate),
        availableDataTimeRange
      )
    };
  }

  public generateAvailableDataTimeRangeMonths(range: DateRange<Moment>) {
    if (!range) { return []; }

    const locale = this.languageSelection.localeSnapshot.lang;
    const startDate = range.startDate;
    const currentEndDate = range.endDate.clone();

    const availableMonths = [];

    while (startDate.toDate() <= currentEndDate.toDate()) {
      const dateWithMonthPrecisionString = this.dateStringConversion.toString(currentEndDate);
      const year = currentEndDate.format('YYYY');
      const languageFormattedDateString = moment(new Date(dateWithMonthPrecisionString), null, locale)
        .format(DateFormats.monthNameShortWithYear);

      availableMonths.push({
        value: dateWithMonthPrecisionString,
        year,
        label: languageFormattedDateString
      });

      currentEndDate.subtract(1, 'months').endOf('month');
    }

    return availableMonths;
  }

  private resolveDate(expected, defaultValue, availableRange) {
    if (!!expected) {
      const tmp = moment(expected).startOf('month');
      const isBetween = tmp.isBetween(
        availableRange.startDate,
        availableRange.endDate);

      return (isBetween || availableRange.startDate.isSame(tmp)) ? expected : defaultValue;
    }

    return defaultValue;
  }

  private createDateList(roomsWithValues: RoomsConsumptionValue[]) {
    return roomsWithValues
      .map(room => room.values)
      .reduce((a, b) => a.concat(b))
      .map(value => moment(value.date));
  }

  private getDefaultDataTimeRange(startDate: Moment, endDate: Moment) {
    const defaultRangeEnd = endDate.clone().endOf('month');
    const defaultRangeStartMin = defaultRangeEnd.clone()
      .subtract(DEFAULT_RANGE_MAX_YEARS, 'year')
      .add(1, 'month')
      .startOf('month');
    const defaultRangeStart = moment.max(startDate, defaultRangeStartMin);

    return { startDate: defaultRangeStart, endDate: defaultRangeEnd };
  }
}
