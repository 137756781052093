import { Injectable } from '@angular/core';
import { RoleManagerService } from './role-manager.service';
import { CurrentUser } from '../models';

@Injectable({
  providedIn: 'root'
})
export class UserSpecificHomePageService {

  public constructor(
    private roleManager: RoleManagerService
  ) { }

  public getForUser(user: CurrentUser) {
    if (this.roleManager.isAdmin(user)) { return '/admin'; }

    if (this.roleManager.isSuperAdmin(user)) { return '/super-admin'; }

    if (this.roleManager.isUser(user)) { return '/welcome'; }

    return null;
  }
}
