import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { RoomType } from '../models';

@Injectable({ providedIn: 'root' })
export class RoomTypesApiService {
  public constructor(
    private http: HttpClient
  ) { }

  public query() {
    return this.http.get<RoomType[]>(
      `${environment.restEndpoint}/roomTypes`
    );
  }
}
