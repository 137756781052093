import { Route } from '@angular/router';
import { DenyAnonymousGuard, ensureTosAccepted, requireRole, UserRole } from '@security/user';
import { CONTENT_ROUTES } from '@content/content.routes';
import { ERRORS_ROUTES } from '@errors/errors.routes';
import { SECURITY_ROUTES } from '@security/security.routes';

export const APP_ROUTES: Route[] = [
  {
    path: '',
    redirectTo: '/welcome',
    pathMatch: 'full',
  },
  ...CONTENT_ROUTES,
  ...ERRORS_ROUTES,
  ...SECURITY_ROUTES,
  {
    path: 'user',
    canActivate: [DenyAnonymousGuard, ensureTosAccepted, requireRole(UserRole.PM)],
    loadChildren: () => import('./user/user.routes').then((m) => m.USER_ROUTES),
  },
  {
    path: 'admin',
    canActivate: [DenyAnonymousGuard, ensureTosAccepted, requireRole(UserRole.ADMIN)],
    loadChildren: () => import('./admin/admin.routes').then((m) => m.ADMIN_ROUTES),
  },
  {
    path: 'super-admin',
    canActivate: [DenyAnonymousGuard, ensureTosAccepted, requireRole(UserRole.SUPER_ADMIN)],
    loadChildren: () => import('./super-admin/super-admin.routes').then((m) => m.SUPER_ADMIN_ROUTES),
  }
];
