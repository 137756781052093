import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { CurrentUserService, RoleManagerService, UserSpecificHomePageService } from '../services';
import { UserRole, CurrentUser } from '../models';

export const requireRole = (role: UserRole): CanActivateFn => {
  const injectDependencies = () => ({
    currentUser: inject(CurrentUserService),
    roleManager: inject(RoleManagerService),
    homePage: inject(UserSpecificHomePageService),
    router: inject(Router)
  });

  return async () => {
    const {
      roleManager,
      homePage,
      router,
      currentUser
    } = injectDependencies();

    let user: CurrentUser;

    try {
      user = await firstValueFrom(currentUser.ensureUser$);
    }
    catch (ex) {
      user = null;
    }

    if (!user) { return false; }

    const hasRole = roleManager.hasRole(user, role);

    if (!hasRole) {
      const destinationUrl = homePage.getForUser(user);

      if (!destinationUrl) { return false; }

      setTimeout(() => {
        router.navigate([destinationUrl]);
      });
    }

    return hasRole;
  };
};
