import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { filter, first, firstValueFrom, map, Observable } from 'rxjs';
import { AlertingEngineSettings, SettingsService } from '@shared/settings';
import { EmailChangeFormComponent, EmailService } from '@security/change-email';
import { CurrentUserService, UserRole } from '@security/user';
import { AsyncPipe } from '@angular/common';
import { DefaultLayoutComponent } from '@shared/layout/components/default-layout';
import { BreadcrumbConfiguration } from '@shared/layout/models';

@Component({
    template: `
		<ic-default-layout [breadcrumb]="breadcrumb">
			<ic-email-change-form #form
				[emailNotificationsDisabled]="emailNotificationsDisabled$ | async"
				(save)="onRequestEmailChange($event)">
			</ic-email-change-form>
		</ic-default-layout>
	`,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [DefaultLayoutComponent, EmailChangeFormComponent, AsyncPipe]
})
export class ChangeEmailComponent implements OnInit {
  @ViewChild('form', { static: true })
  public form: EmailChangeFormComponent;

  public emailNotificationsDisabled$: Observable<boolean>;
  public readonly breadcrumb: BreadcrumbConfiguration = [
    { label: 'CHANGE_EMAIL' }
  ];

  public constructor(
    private settings: SettingsService,
    private currentUser: CurrentUserService,
    private changeDetector: ChangeDetectorRef,
    private email: EmailService
  ) { }

  public async ngOnInit() {
    const { role } = await firstValueFrom(this.currentUser.ensureUser$);

    this.subscribeToNotificationsFlag(role);
    this.settings.refresh();
  }

  public onRequestEmailChange({ payload, updateAlertingEmail }) {
    this.email
      .requestEmailChange(payload)
      .subscribe({
        next: () => this.onEmailChangeRequested(updateAlertingEmail, payload.newEmailAddress),
        complete: () => this.form.reset()
      });
  }

  private async onEmailChangeRequested(updateAlertingEmail: boolean, newEmailAddress: string) {
    if (!updateAlertingEmail) { return; }

    const { id } = await firstValueFrom(this.currentUser.ensureUser$);
    const settings = await firstValueFrom(this.settings.settings$.pipe(first()));

    this.email
      .updateAlertingEmailIfRequested(id, newEmailAddress, settings)
      .subscribe();
  }

  private subscribeToNotificationsFlag(role: string) {
    this.emailNotificationsDisabled$ = this.settings.settings$.pipe(
      filter(() => role === UserRole.PM),
      map(settings => this.areEmailNotificationsDisabled(settings.alertingEngineSettingsModel))
    );

    this.changeDetector.markForCheck();
  }

  private areEmailNotificationsDisabled(settings: AlertingEngineSettings): boolean {
    const emailNotificationsPossible = settings?.emailNotificationsPossible;
    const emailNotificationsEnabled = settings?.emailNotificationsEnabled;

    return !(emailNotificationsPossible && emailNotificationsEnabled);
  }
}
