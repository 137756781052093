import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PieChartPullOutServiceFactory {

  public get() {
    return new PieChartPullOutService();
  }
}

function PieChartPullOutService() {

  let transformationParameter: any = {};
  let arcGlobal;
  let PULL_OUT_PIE_SEGMENT;
  let PULL_OUT_DISTANCE;
  let PULL_OUT_PIE_SEGMENT_ALLOWED;

  const setActiveRoom = (roomId) => {
    transformationParameter.activeRoomState.currentActiveRoom = roomId;
  };

  // eslint-disable-next-line max-len
  const isSameRoom = () => transformationParameter.activeRoomState.currentActiveRoom === transformationParameter.activeRoomState.previousActiveRoom;

  const isSelectedRoom = (d) => d.data.uuid === transformationParameter.activeRoomState.currentActiveRoom;

  const getPullOutPositionOfSlice = (d) => {
    setActiveRoom(d.data.uuid);

    const c = arcGlobal.centroid(d);
    const x = c[0];
    const y = c[1];
    const h = Math.sqrt(x * x + y * y);
    const pullOutPositionX = ((x / h) * PULL_OUT_DISTANCE);
    const pullOutPositionY = ((y / h) * PULL_OUT_DISTANCE);

    return { x: pullOutPositionX, y: pullOutPositionY };
  };

  const getPullOutPosition = (d) => ({
    distance: PULL_OUT_DISTANCE,
    position: (arcGlobal) ? getPullOutPositionOfSlice(d) : { x: 0, y: 0 }
  });

  const getPullInPosition = () => ({
    distance: 0,
    position: { x: 0, y: 0 }
  });

  const getPullTransformationParameterOnSelectRoom = (d) => {
    if (isSameRoom()) {
      setActiveRoom(null);

      return getPullInPosition();
    } else {
      return getPullOutPosition(d);
    }
  };

  const getPullTransformationParameterOnDataUpdate = (d) => {
    if (transformationParameter.activeRoomState.currentActiveRoom && PULL_OUT_PIE_SEGMENT_ALLOWED) {
      return getPullOutPosition(d);
    } else {
      return getPullInPosition();
    }
  };

  const getPullTransformationParameterForSelectedRoom = (d) => {
    if (PULL_OUT_PIE_SEGMENT && PULL_OUT_PIE_SEGMENT_ALLOWED) {
      return getPullTransformationParameterOnSelectRoom(d);
    } else {
      return getPullTransformationParameterOnDataUpdate(d);
    }
  };

  this.getPullTransformationParameter = (arc, d, pullOutPieSegment, pullOutPieSegmentAllowed, activeRoomState, layoutPullOutDistance) => {
    arcGlobal = arc;
    PULL_OUT_DISTANCE = layoutPullOutDistance;
    PULL_OUT_PIE_SEGMENT = pullOutPieSegment;
    PULL_OUT_PIE_SEGMENT_ALLOWED = pullOutPieSegmentAllowed;

    transformationParameter = {
      distance: 0,
      position: { x: 0, y: 0 },
      activeRoomState
    };

    if (isSelectedRoom(d)) {
      const transformation = getPullTransformationParameterForSelectedRoom(d);

      transformationParameter.distance = transformation.distance;
      transformationParameter.position = transformation.position;
    }

    return transformationParameter;
  };
}
