import { Injectable } from '@angular/core';
import { ChartOptions } from '@user/consumption';

const defaultOptions: ChartOptions = {
  chart: {
    marginBottom: 5,
  },
  xAxis: {
    height: 20,
    paddingTop: 14,
  },
  header: {
    height: 20,
  },
  line: {
    margin: {
      top: 7,
      right: 10,
      bottom: 0,
      left: 10,
    },
  },
  nodes: {
    radius: 4,
  },
  nodeLabels: {
    width: 45,
    height: 20,
    marginVertical: 9,
    arrowWidth: 10,
  },
  yAxis: {
    marginLeft: 12,
  },
};

@Injectable({
  providedIn: 'root'
})
export class ChartLayoutService {
  public get defaultOptions() {
    return defaultOptions;
  }

  public layout(numberOfIntervals, options: ChartOptions) {
    options = Object.assign({}, defaultOptions, options);
    const layout: any = {};

    layout.line = {
      margin: options.line.margin,
      x: options.line.margin.left,
      y: options.header.height + options.line.margin.top,
      width:
        options.chart.width -
        options.line.margin.left -
        options.line.margin.right,
      height:
        options.chart.height -
        options.chart.marginBottom -
        options.xAxis.height -
        options.header.height -
        options.line.margin.top -
        options.line.margin.bottom,
    };
    layout.area = layout.line;

    layout.yAxis = {
      x: options.yAxis.marginLeft,
      y: layout.line.y,
    };

    layout.xAxis = {
      height: options.xAxis.height,
      paddingTop: options.xAxis.paddingTop,
      x: layout.line.x,
      y:
        options.chart.height -
        options.chart.marginBottom -
        options.xAxis.height,
    };

    layout.nodeLabels = {
      x: layout.line.x,
      y: layout.line.y,
      box: {
        width: options.nodeLabels.width,
        height: options.nodeLabels.height,
        marginVertical: options.nodeLabels.marginVertical,
      },
      arrow: {
        width: options.nodeLabels.arrowWidth,
      },
    };

    layout.yearSeparators = {
      x: layout.line.x,
      y: 0,
      width: options.chart.width,
      height: options.chart.height - options.chart.marginBottom,
      paddingLeftRight: 5,
      paddingTop: 12,
    };

    layout.nodes = {
      x: layout.line.x,
      y: layout.line.y,
      radius: options.nodes.radius,
    };

    layout.height =
      options.chart.height * numberOfIntervals - options.chart.marginBottom;
    layout.width = options.chart.width;

    layout.bar = {
      width: (layout.line.width + layout.line.width / 12) / 12,
      height: layout.height,
    };

    layout.intervals = [];
    for (let i = 0; i < numberOfIntervals; i++) {
      layout.intervals.push({
        y: i * options.chart.height,
      });
    }

    return layout;
  }
}
