import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs';
import { environment } from '@environment';
import { DeleteRoomPayload, RoomDetails, RoomsConsumptionFromApi, SaveRoomPayload } from '../models';
import { RoomConsumptionsConversionService } from './room-consumptions-conversion.service';

@Injectable({ providedIn: 'root' })
export class RoomsApiService {
  public constructor(
    private http: HttpClient,
    private conversion: RoomConsumptionsConversionService
  ) { }

  public getRoomsConsumption({ uuid, consumptionType, unit, timeunit }) {
    return this.http
      .get<RoomsConsumptionFromApi>(
        `${environment.restEndpoint}/user/${uuid}/consumption/rooms/${consumptionType}/${unit}/${timeunit}`
      )
      .pipe(
        map(result => this.conversion.convertDateStringToDateObject(result))
      );
  }

  public getNormalizedRoomsConsumption({ uuid, consumptionType, unit, timeunit }) {
    return this.http
      .get<RoomsConsumptionFromApi>(
        `${environment.restEndpoint}/user/${uuid}/consumption/rooms/${consumptionType}/${unit}/${timeunit}/floorNormalized`
      )
      .pipe(
        map(result => this.conversion.convertDateStringToDateObject(result))
      );
  }

  public query({ userUuid, roomUuid }: { userUuid: string; roomUuid?: string }) {
    const urlPostfix = !!roomUuid
      ? `/${roomUuid}`
      : '';

    return this.http
      .get<RoomDetails[]>(
        `${environment.restEndpoint}/user/${userUuid}/room${urlPostfix}`
      );
  }

  public delete({ userUuid, uuid, forceDelete }: DeleteRoomPayload) {
    return this.http
      .delete(
        `${environment.restEndpoint}/user/${userUuid}/room/${uuid}`,
        { params: { forceFlag: forceDelete } }
      );
  }

  public save(args: SaveRoomPayload) {
    return !args.details.uuid
      ? this.addRoom(args)
      : this.updateRoom(args);
  }

  private updateRoom({ details, userUuid }: SaveRoomPayload) {
    const { uuid } = details;

    return this.http
      .put<RoomDetails>(
        `${environment.restEndpoint}/user/${userUuid}/room/${uuid}`,
        { ...details }
      );
  }

  private addRoom({ details, userUuid }: SaveRoomPayload) {
    return this.http
      .post<RoomDetails>(
        `${environment.restEndpoint}/user/${userUuid}/room`,
        { ...details }
      );
  }
}
