export const environment = {
  production: true,
  debug: false,
  restEndpoint: '/api',
  keycloak: {
    url: (window as any).icKeycloakUrl,
    realm: (window as any).icKeycloakRealm,
    clientId: (window as any).icKeycloakClientId,
  }
};
