import {ChangeDetectionStrategy, ChangeDetectorRef, Component} from '@angular/core';
import {ChangePasswordFailureReason} from '@security/change-password/models/change-password-failure-reason.model';
import {ChangePasswordPayload} from '@security/change-password/models/change-password-payload.model';
import {PasswordService} from '@security/change-password/services/password.service';
import {CurrentUserService} from '@security/user';
import {NotificationService, Notification} from '@shared/notifications';
import {firstValueFrom} from 'rxjs';
import { PasswordPolicyBoxComponent } from '../password-policy-box/password-policy-box.component';
import { PasswordFormComponent } from '../password-form/password-form.component';


@Component({
    selector: 'ic-change-password',
    templateUrl: './password.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [PasswordFormComponent, PasswordPolicyBoxComponent]
})
export class PasswordComponent {
  public completed: boolean;

  public constructor(
    private password: PasswordService,
    private currentUser: CurrentUserService,
    private changeDetector: ChangeDetectorRef,
    private notifications: NotificationService
  ) {
  }

  public async onChangePassword(value: ChangePasswordPayload) {
    const {id} = await firstValueFrom(this.currentUser.ensureUser$);

    this.completed = false;

    this.password
      .changePassword(id, value)
      .subscribe({
        next: () => this.onPasswordChanged(),
        error: (reason: ChangePasswordFailureReason) => this.onChangePasswordError(reason)
      });
  }

  private onPasswordChanged() {
    this.resetForm();
    this.notifications.clear();
  }

  private onChangePasswordError(reason: ChangePasswordFailureReason) {
    this.notifications.show(
      Notification.toast().asError({
        messageKey: this.getErrorMessageKey(reason)
      })
    );

    this.resetForm();
  }

  private resetForm() {
    this.completed = true;
    this.changeDetector.markForCheck();
  }

  private getErrorMessageKey(reason: ChangePasswordFailureReason) {
    switch (reason) {
      case ChangePasswordFailureReason.USED_RECENTLY: {
        return 'ERROR_PASSWORD_RECENTLY_USED';
      }
      case ChangePasswordFailureReason.CURRENT_PASSWORD_IS_WRONG: {
        return 'CHANGE_PASSWORD_VALIDATION_ERROR';
      }
      default: {
        return 'CHANGE_PASSWORD_ERROR';
      }
    }
  }
}
