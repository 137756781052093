import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  catchError, concatMap, defaultIfEmpty,
  EMPTY, first, forkJoin, map,
  Observable, shareReplay,
  switchMap,
  tap
} from 'rxjs';
import { NotificationService, Notification } from '@shared/notifications';
import { CurrentUserService } from '@security/user';
import { DeleteRoomPayload, RoomDetails, RoomType, SaveRoomPayload } from '../models';
import { RoomsApiService } from './rooms-api.service';
import { RoomTypesApiService } from './room-types-api.service';
import { MeteringPointResource } from './metering-point-api.service';

@Injectable({
  providedIn: 'root'
})
export class RoomService {
  public rooms$: Observable<RoomDetails[]>;
  public roomTypes$: Observable<RoomType[]>;

  private roomsBus = new BehaviorSubject<string>(undefined);
  private roomTypesBus = new BehaviorSubject<void>(undefined);

  public constructor(
    private resource: RoomsApiService,
    private typesResource: RoomTypesApiService,
    private meteringPointsResource: MeteringPointResource,
    private currentUser: CurrentUserService,
    private notifications: NotificationService
  ) {
    this.subscribeToRooms();
    this.subscribeToRoomTypes();
  }

  public refresh(roomUuid?: string) {
    this.roomsBus.next(roomUuid);
  }

  public delete(args: DeleteRoomPayload) {
    return this.resource.delete(args).pipe(
      tap(() => this.onCrudSucceeded()),
      catchError(() => {
        this.onCrudFailed();
        return EMPTY;
      })
    );
  }

  public save(args: SaveRoomPayload) {
    const { userUuid } = args;

    const deleteRequests = (roomUuid: string) => args.assignedMeteringPoints
      .removedUuids
      .map(meteringPointUuid => this.meteringPointsResource.delete({
        userUuid,
        roomUuid,
        meteringPointUuid
      }));

    const addRequests = (roomUuid: string) => args.assignedMeteringPoints
      .addedUuids
      .map(meteringPointUuid => this.meteringPointsResource.add({
        userUuid,
        roomUuid,
        meteringPointUuid
      }));

    return this.resource.save(args).pipe(
      concatMap(({ uuid }) => forkJoin([
        ...deleteRequests(uuid),
        ...addRequests(uuid)
      ]).pipe(
        defaultIfEmpty(null),
      )),
      catchError(() => {
        this.onCrudFailed();
        return EMPTY;
      }),
      tap(() => this.onCrudSucceeded())
    );
  }

  public nameAlreadyExists(name: string, uuid?: string) {
    const sanitize = (value: string) => !value
      ? null
      : value.toLowerCase().trim();

    return this.rooms$.pipe(
      map(rooms =>
        rooms.some(room =>
          sanitize(room.roomLabel) === sanitize(name) &&
          room.uuid !== uuid
        )
      ),
      first()
    );
  }

  private onCrudSucceeded() {
    this.notifications.show(
      Notification.toast().asSuccess({
        messageKey: 'UPDATE_SETTINGS_SUCCESS'
      })
    );
  }

  private onCrudFailed() {
    this.notifications.show(
      Notification.toast().asError({
        messageKey: 'UPDATE_SETTINGS_ERROR'
      })
    );
  }

  private subscribeToRooms() {
    this.rooms$ = this.roomsBus.pipe(
      switchMap((roomUuid) => this.currentUser.ensureUser$
        .pipe(
          first(),
          map(({ uuid }) => ({ userUuid: uuid, roomUuid }))
        )
      ),
      switchMap(args => this.resource.query(args)),
      shareReplay(1)
      // TODO: catchError
    );
  }

  private subscribeToRoomTypes() {
    this.roomTypes$ = this.roomTypesBus.pipe(
      switchMap(() => this.typesResource.query()),
      shareReplay(1)
    );
  }
}
