<mat-form-field [floatLabel]="'always'" class="flex-auto gt-xs:pr-3" [class.field-ic-attention]="includeTooltip()">
  <mat-label
    >{{ "NEW_PASSWORD_LABEL" | translate }}
    @if (includeTooltip()) {
      <ic-attention class="inline-flex -mt-3" icon="error" color="warn">
        {{ "PASSWORD_POLICY_DESCRIPTION" | translate }}
      </ic-attention>
    }
  </mat-label>
  <input
    matInput
    [formControlName]="FormKeys.NEW_PASSWORD"
    class="form-control"
    type="password"
    (change)="changed.next()"
  />

  @if (controlHasError(FormKeys.NEW_PASSWORD)) {
    <mat-error>
      @if (controlHasError(FormKeys.NEW_PASSWORD, ValidationKeys.REQUIRED)) {
        {{ "ERROR_PASSWORD_REQUIRED" | translate }}
      }
      @if (controlHasError(FormKeys.NEW_PASSWORD, ValidationKeys.STRONG_PASSWORD)) {
        {{ "ERROR_PASSWORD_TOO_WEAK" | translate }}
      }
    </mat-error>
  }
</mat-form-field>

<mat-form-field [floatLabel]="'always'" class="flex-auto gt-xs:pr-3">
  <mat-label>{{ "NEW_PASSWORD_CONFIRMATION_LABEL" | translate }}</mat-label>

  <input
    matInput
    [formControlName]="FormKeys.NEW_PASSWORD_CONFIRMATION"
    class="form-control"
    type="password"
    (change)="changed.next()"
  />

  @if (controlHasError(FormKeys.NEW_PASSWORD_CONFIRMATION)) {
    <mat-error>
      {{ "ERROR_PASSWORD_REQUIRED" | translate }}
    </mat-error>
  }
</mat-form-field>

@if (formGroupHasError(ValidationKeys.PASSWORDS_DONT_MATCH)) {
  <div class="mb-4">
    <mat-error>
      <fuse-alert dismissible="false" type="error" appearance="outline">
        {{ "ERROR_PASSWORD_CONFIRMATION" | translate }}
      </fuse-alert>
    </mat-error>
  </div>
}
