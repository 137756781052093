/* eslint-disable */
// NOTE: there's a lot of legacy code here wrapped inside the Angular service.
// Pls have in mind that even a minor refactoring can break it. Ensure you know what you're doing.
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ChartAreaServiceFactory {
  get(containerClass: string) {
    return (new LegacyChartAreaService()).area(containerClass)
  }
}

function LegacyChartAreaService() {

  this.area = function (areaContainerClass: string) {
    let layout, intervals, subsets;
    const dotAreaContainerClass = '.' + areaContainerClass;

    const area = function (selection) {
      selection.each(function (interval) {
        const areas = d3.select(this).selectAll('path').data(subsets);

        areas.exit().remove();
        areas.enter().append('path').attr('class', 'area');
        areas.transition().attr('d', function (subset) {
          return interval.area(subset);
        });
      });
    };

    const g = function (selection) {
      selection.each(function () {
        const innerG = d3
          .select(this)
          .selectAll(dotAreaContainerClass)
          .data(intervals);

        innerG.exit().remove();
        innerG.enter().append('g').attr('class', areaContainerClass);
        innerG.attr('transform', function (interval) {
          return 'translate(' + layout.x + ', ' + (layout.y + interval.y) + ')';
        });
        innerG.call(area);
      });
    };

    g.layout = function (_) {
      if (!arguments.length) {
        return layout;
      }
      layout = _;

      return g;
    };

    g.intervals = function (_) {
      if (!arguments.length) {
        return intervals;
      }
      intervals = _;

      return g;
    };

    g.subsets = function (_) {
      if (!arguments.length) {
        return subsets;
      }
      subsets = _;

      return g;
    };

    return g;
  };
}
