import { enableProdMode, APP_INITIALIZER, importProvidersFrom } from '@angular/core';
import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { CookieModule } from 'ngx-cookie';
import { ToastrModule } from 'ngx-toastr';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { MissingTranslationHandlerParams } from '@ngx-translate/core/lib/missing-translation-handler';
import { TranslationLoadingService } from './app/shared/localization';
import { TranslateModule, TranslateLoader, MissingTranslationHandler } from '@ngx-translate/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { supportedConsumptionTypes, supportedConsumptionTypesFactory } from '@user/consumption';
import { DateFormats } from '@shared/common';
import { provideMomentDateAdapter } from '@angular/material-moment-adapter';
import { AttachSpinnerToMatchingRequestInterceptor } from '@shared/spinner';
import { icWorkingDomain, icWorkingDomainFactory, icBrand, icBrandFactory } from '@shared/branding';
import { MatDialogModule } from '@angular/material/dialog';
import { HandleHttpUnauthorizedInterceptor } from '@security/auth';
import { HTTP_INTERCEPTORS, withInterceptorsFromDi, provideHttpClient } from '@angular/common/http';
import { KeycloakService, KeycloakAngularModule } from 'keycloak-angular';
import { provideRouter } from '@angular/router';
import { APP_ROUTES } from './app/app.routes';

const initializeKeycloak = (keycloak: KeycloakService) => () =>
  keycloak.init({
    config: environment.keycloak,
    initOptions: {
      checkLoginIframe: true,
      checkLoginIframeInterval: 25,
    },
    loadUserProfileAtStartUp: true,
    bearerExcludedUrls: [
      // NOTE: this endpoint intentionally sets the 'Authorization' header to null.
      // Such behavior would be overwritten by Keycloak interceptor if URL was not excluded
      '/countries'
    ]
  });

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      BrowserModule,
      KeycloakAngularModule,
      TranslateModule.forRoot({
        defaultLanguage: 'en-gb',
        loader: {
          provide: TranslateLoader,
          useClass: TranslationLoadingService
        },
        missingTranslationHandler: {
          provide: MissingTranslationHandler,
          useValue: { handle: (params: MissingTranslationHandlerParams) => params.key }
        }
      }),
      AngularSvgIconModule.forRoot(),
      NgxSpinnerModule.forRoot(),
      ToastrModule.forRoot(),
      CookieModule.withOptions()
    ),
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
    provideRouter(APP_ROUTES),
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HandleHttpUnauthorizedInterceptor,
      multi: true
    },
    importProvidersFrom(MatDialogModule),
    { provide: icWorkingDomain, useFactory: icWorkingDomainFactory },
    { provide: icBrand, useFactory: icBrandFactory, deps: [icWorkingDomain] },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AttachSpinnerToMatchingRequestInterceptor,
      multi: true
    },
    provideMomentDateAdapter({
      parse: {
        dateInput: DateFormats.date
      },
      display: {
        dateInput: DateFormats.date,
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
        monthLabel: '',
      }
    }, { useUtc: true }),
    { provide: supportedConsumptionTypes, useFactory: supportedConsumptionTypesFactory }
  ]
})
.catch(err => console.error(err));
