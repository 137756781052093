export interface ConsumptionTendencies {
  [type: string]: ConsumptionTendenciesGroupedByUnit;
}

export interface ConsumptionTendenciesGroupedByUnit {
  [unit: string]: ConsumptionTendency;
}

export interface ConsumptionTendency {
  percent: string;
  present: boolean;
  primary: boolean;
  tendency: string;
  type: string;
  unit: string; // TODO maybe only proper values
}

export enum Tendency {
  EQUAL = 'equal',
  UP = 'up',
  DOWN = 'down'
}
