/* eslint-disable */
// NOTE: there's a lot of legacy code here wrapped inside the Angular service.
// Pls have in mind that even a minor refactoring can break it. Ensure you know what you're doing.
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { DateFormats } from '@shared/common';

@Injectable({
  providedIn: 'root'
})
export class ChartXAxisServiceFactory {
  get() {
    return (new LegacyChartXAxisService()).xAxis();
  }
}

function LegacyChartXAxisService() {
  this.xAxis = function () {
    let intervals, layout;
    let selectionCache;

    const dateFormatter = function (date) {
      return moment(date)
        .format(DateFormats.monthName)
        .substring(0, 3)
        .toUpperCase();
    };

    const xAxisLabels = function (selection) {
      selection.each(function (interval) {
        const ticks = d3.time.months(
          interval.from,
          moment(interval.to).add(1, 'months').toDate(),
          1
        );
        const text = d3.select(this).selectAll('text').data(ticks);

        text.exit().remove();
        text
          .enter()
          .append('text')
          .attr('text-anchor', 'middle')
          .attr('class', 'xaxis-label');
        text
          .text(function (date) {
            return dateFormatter(date);
          })
          .classed({
            permanent: function (date, index) {
              return (
                index === 0 ||
                index === ticks.length - 1 ||
                date.getMonth() === 0
              );
            },
          })
          .attr('x', function (date) {
            return interval.xScale(date);
          })
          .attr('y', d3.functor(layout.paddingTop));
      });
      selectionCache = selection.selectAll('.xaxis-label');
    };

    const xAxis = function (selection) {
      selection.each(function () {
        const g = d3.select(this).selectAll('.xaxis').data(intervals);

        g.exit().remove();
        g.enter().append('g').attr('class', 'xaxis');
        g.attr('transform', function (interval) {
          return 'translate(' + layout.x + ', ' + (layout.y + interval.y) + ')';
        });
        g.call(xAxisLabels);
      });
    };

    xAxis.updateActive = function (selection, currentDate) {
      selectionCache.classed('active', function (date) {
        return date.getMonth() === currentDate.getMonth();
      });
    };

    xAxis.intervals = function (_) {
      if (!arguments.length) {
        return intervals;
      }
      intervals = _;

      return xAxis;
    };

    xAxis.layout = function (_) {
      if (!arguments.length) {
        return layout;
      }
      layout = _;

      return xAxis;
    };

    return xAxis;
  };
}
