import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[ngInclude]',
    standalone: true
})
export class NgIncludeDirective {

  public constructor(
    public viewContainer: ViewContainerRef
  ) { }

}
