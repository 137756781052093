import { InjectionToken } from '@angular/core';

export const icBrand = new InjectionToken<string>('icBrand');
export const icBrandFactory = (icWorkingDomain: string) => {
  // return 'db18'; // TODO hardcoded
  // return 'db17'; // TODO hardcoded

  if (!window.location.hostname.includes(icWorkingDomain)) {
    return null;
  }

  const brandingDomain = window.location.hostname
    .replace(icWorkingDomain, '')
    .replace(/\.$/, '');

  return brandingDomain !== ''
    ? brandingDomain
    : null;
};
