export enum ConsumptionType {
  HEATING = 'HEATING',
  HEAT = 'HEAT',
  WATER = 'WATER',
  HOT_WATER = 'HOT_WATER',
  COLD_WATER = 'COLD_WATER',
  T_LOGGER = 'T_LOGGER',
  POWER = 'POWER',
  ELECTRICITY = 'ELECTRICITY',
  COOLING = 'COOLING',
  COOL = 'COOL',
  BILLED = 'BILLED', // TODO: comment out when possible, probably not supported by API anymore
  CALCULATED = 'CALCULATED', // TODO: comment out when possible, probably not supported by API anymore
  OTHER = 'OTHER'
};
