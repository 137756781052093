import { Injectable } from '@angular/core';
import { CountryFeatureService } from '@security/user';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DefaultLayoutService {

    public expanded$: Subject<boolean> = new BehaviorSubject<boolean>(true);

    public constructor(private countryFeature: CountryFeatureService) {
        this.countryFeature.moreThanOneOption().then(result => {
            this.expanded$.next(result);
        });
    }

    public setUserState(expanded:boolean) {
        this.expanded$.next(expanded);
    }


}
