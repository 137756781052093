export enum ConsumptionUnit {
  DEVICE_UNITS = 'DEVICE_UNITS', // TODO not sure if should be here
  DEVICE_UNIT = 'DEVICE_UNIT',
  HCA_UNITS = 'HCA_UNITS',
  KWH = 'KWH',
  KILO_WATT_HOURS = 'KILO_WATT_HOURS',
  GIGA_JOULE = 'GIGA_JOULE',
  MWH = 'MWH',
  GJ = 'GJ',
  KJ = 'KJ',
  GC = 'GC',
  CUBIC_METER = 'CUBIC_METER',
  L = 'L',
  M_3 = 'M_3',
  M3 = 'M3',
}
