import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environment';
import { AddUserPayload, UpdateUserDemoFlagPayload, UpdateUserPayload, User } from '@security/user';

@Injectable({ providedIn: 'root' })
export class UserApiService {
  public constructor(
    private http: HttpClient
  ) { }

  public get(userId: number) {
    return this.http.get<User>(
      `${environment.restEndpoint}/user/${userId}`
    );
  }

  public update(payload: UpdateUserPayload) {
    return this.http.put(
      `${environment.restEndpoint}/user/${payload.id}`,
      payload
    );
  }

  public add(payload: AddUserPayload) {
    return this.http.post<User>(
      `${environment.restEndpoint}/user`,
      payload
    );
  }

  public updateDemoFlag(payload: UpdateUserDemoFlagPayload) {
    return this.http.put(
      `${environment.restEndpoint}/user/${payload.id}`,
      payload
    );
  }

  public generatePassword(userId: number) {
    return this.http.post<User>(
      `${environment.restEndpoint}/user/${userId}/password`,
      {}
    );
  }

  public remove(userId: number) {
    return this.http.delete(
      `${environment.restEndpoint}/user/${userId}`
    );
  }
}
