import { Injectable } from '@angular/core';
import { IstaColorPaletteService } from '@shared/consumption';
import { PieChartPullOutServiceFactory } from './pie-chart-pull-out.service';

@Injectable({
  providedIn: 'root'
})
export class PieChartSlicesServiceFactory {

  public constructor(
    private istaColorPaletteService: IstaColorPaletteService,
    private pieChartPullOutService: PieChartPullOutServiceFactory
  ) { }

  public get() {
    return (
      new PieChartSlicesService(this.istaColorPaletteService, this.pieChartPullOutService.get())
    ).slices();
  }
}

function PieChartSlicesService(istaColorPalletService: IstaColorPaletteService, PieChartPullOutService) {
  this.slices = () => {
    let rooms; let sliceArcs; let arc; let layout;
    const pie = d3.layout.pie().sort(null);

    pie.value((d) => d.sum);

    let activeRoomState: { currentActiveRoom: string; previousActiveRoom: string } = {
      currentActiveRoom: null,
      previousActiveRoom: null
    };
    let pullOutPieSegment = false;
    let pullOutPieSegmentAllowed = false;

    const getTransformationParameter = (d) => {
      const transformationParameter = PieChartPullOutService.getPullTransformationParameter(
        arc,
        d,
        pullOutPieSegment,
        pullOutPieSegmentAllowed,
        activeRoomState,
        layout.pullOutDistance
      );

      activeRoomState = transformationParameter.activeRoomState;

      return transformationParameter.position;
    };

    const slices = (selection) => {

      arc = d3.svg.arc()
        .innerRadius(layout.innerRadius)
        .outerRadius(layout.outerRadius);

      sliceArcs = selection.select('g.pie-slice-group')
        .attr('transform', 'translate(' + layout.centerX + ',' + layout.centerY + ')')
        .selectAll('path.pie-slice').data(pie(rooms));

      sliceArcs.exit().remove();

      sliceArcs
        .enter()
        .append('path')
        .attr('class', 'pie-slice');

      sliceArcs
        .attr('transform', (d) => {
          const position = getTransformationParameter(d);
          return 'translate(' + position.x + ',' + position.y + ')';
        })
        // eslint-disable-next-line no-underscore-dangle
        .attr('fill', (d) => istaColorPalletService.colorAsCss(d.data._index))
        .attr('stroke', () => 'white')
        .attr('stroke-width', () => 3)
        .attr('title', (d) => d.data.label)
        // eslint-disable-next-line no-underscore-dangle
        .attr('data-index', (d) => d.data._index)
        .attr('d', arc);
    };

    slices.activeRoomState = (activeRoom: string) => {
      if (!arguments.length) {
        return activeRoomState;
      }
      activeRoomState.previousActiveRoom = activeRoomState.currentActiveRoom;
      activeRoomState.currentActiveRoom = activeRoom;

      return slices;
    };

    slices.pullOutPieSegment = (_) => {
      if (!arguments.length) {
        return pullOutPieSegment;
      }

      pullOutPieSegment = _;
      return slices;
    };

    slices.pullOutPieSegmentAllowed = (_) => {
      if (!arguments.length) {
        return pullOutPieSegmentAllowed;
      }

      pullOutPieSegmentAllowed = _;
      return slices;
    };

    slices.rooms = (_) => {
      if (!arguments.length) {
        return rooms;
      }

      rooms = _;
      return slices;
    };

    slices.layout = (_) => {
      if (!arguments.length) {
        return layout;
      }

      layout = _;
      return slices;
    };

    return slices;
  };
}
