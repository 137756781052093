import { Injectable } from '@angular/core';
import { DateFormats } from '@shared/common';
import * as moment from 'moment';
import { Moment } from 'moment';

@Injectable({
  providedIn: 'root'
})
export class DateStringConversionService {
  public toString(date: Moment) {
    return date.format(DateFormats.yearMonth).toString();
  }

  public toMomentDate(dateStringValue: string) {
    return moment(dateStringValue, DateFormats.yearMonth);
  }
}
