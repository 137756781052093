import { Injectable } from '@angular/core';
import { formatNumber } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { hareNiemeyer as hareNiemeyerFilter } from '../services';

@Injectable({
  providedIn: 'root'
})
export class PieChartRoomDataServiceFactory {

  public constructor(
    private translate: TranslateService
  ) { }

  public get() {
    return new PieChartRoomDataService(this.translate);
  }
}

function PieChartRoomDataService($translate: TranslateService) {
  // eslint-disable-next-line @typescript-eslint/no-this-alias
  const that = this;
  const PERCENTAGE_PRECISION = 1;

  const roomsWithPercentage = (rooms, total) => {
    const result = rooms.map((d: any) => {
      d.percentage = d.sum * 100 / total;

      return d;
    });

    return result;
  };

  const addLocalizedPercentage = (rooms, percentagePrecision) => {
    const locale = $translate.currentLang.substring(0, 2);
    const result = rooms.map((d: any) => {
      d.percentageLocalized = formatNumber(d.percentage, locale, `1.${percentagePrecision}-${percentagePrecision}`);

      return d;
    });

    return result;
  };

  const addIndexToRooms = (rooms) => {
    const result = rooms.map((d: any, i) => {
      // eslint-disable-next-line no-underscore-dangle
      d._index = i;
      return d;
    });

    return result;
  };

  const roomsAboveZeroPercentage = (input) => input.filter((item: any) => item.percentage > 0);

  const calculateTotal = (values: { sum: number }[]) => {
    let total;

    if (!!values) {
      total = values.reduce((acc, item: { sum: number }) => acc + item.sum, 0);
    } else {
      total = 0;
    }

    return total;
  };

  that.roomsAboveZeroPercentage = roomsAboveZeroPercentage;
  that.calculateTotal = calculateTotal;

  that.convertRoomsToLabelsData = (rooms, percentage_precision) => {
    const total = calculateTotal(rooms);
    const roomsWithPercentageLevelled = hareNiemeyerFilter(roomsWithPercentage(rooms, total), 'percentage', percentage_precision, 100);
    const roomsWithPercentageLevelledAboveZero = roomsAboveZeroPercentage(roomsWithPercentageLevelled);
    const roomsWithPercentageAndLocalizationAboveZero = addLocalizedPercentage(
      roomsWithPercentageLevelledAboveZero,
      PERCENTAGE_PRECISION
    );

    return roomsWithPercentageAndLocalizationAboveZero;
  };

  that.convertRoomsToIconsData = (rooms, percentage_precision) => {
    const total = calculateTotal(rooms);
    const roomsWithPercentageLevelled = hareNiemeyerFilter(roomsWithPercentage(rooms, total), 'percentage', percentage_precision, 100);

    return roomsWithPercentageLevelled;
  };

  that.convertRoomsToSlicesData = (rooms, percentage_precision) => {
    const total = calculateTotal(rooms);
    const localizedWithIndex = addLocalizedPercentage(addIndexToRooms(rooms), percentage_precision);
    const slicesRoomData = roomsAboveZeroPercentage(roomsWithPercentage(localizedWithIndex, total));

    return slicesRoomData;
  };
}
