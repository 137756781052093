import {
  ChangeDetectionStrategy,
  Component,
  Input,
  TemplateRef, ViewChild
} from '@angular/core';
import { NgIf, NgClass } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';
import { MatDialog, MatDialogContent } from '@angular/material/dialog';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DialogComponent } from '@ista-ui/angular';

@Component({
  selector: 'ic-attention',
  template: `
    <button type="button" mat-icon-button [color]="color" (click)="openDialog()">
      <mat-icon class="material-symbols-fill">{{ icon }}</mat-icon>
    </button>

    <ng-template #messageDialog>
      <mat-dialog-content class="text-gray-800 text-lg">
        <ng-content></ng-content>
      </mat-dialog-content>
    </ng-template>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, NgClass, MatIcon, MatIconButton, MatDialogContent, TranslateModule]
})
export class AttentionComponent {
  @ViewChild('messageDialog', { static: true })
  private messageDialogTemplate: TemplateRef<any>;

  @Input()
  public title: string;
  @Input()
  public color = 'primary';
  @Input()
  public icon = 'help';

  public constructor(
    private dialog: MatDialog,
    private translations: TranslateService
  ) { }

  public openDialog() {

    const _title = this.title ?? this.translations.instant('NOTIFICATION');
    const color = this.color === 'warn'
      ? 'warning'
      : this.color;
    this.dialog.open(DialogComponent, {
      hasBackdrop: true,
      data: {
        title: _title,
        content: this.messageDialogTemplate,
        headerIcon: { icon: this.icon, color }
      }
    });
  }
}
