import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, FormGroupDirective, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { debounceTime, Subscription } from 'rxjs';
import { FormKeys as NestedFormKeys, ValidationKeys, NewPasswordFieldsSectionComponent } from '../new-password-fields-section/new-password-fields-section.component';
import { PasswordStrengthService } from '@security/change-password';
import { ChangePasswordPayload } from '@security/change-password';
import { TranslateModule } from '@ngx-translate/core';
import { MatIcon } from '@angular/material/icon';
import { MatButton } from '@angular/material/button';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel, MatError } from '@angular/material/form-field';

enum FormKeys {
  CURRENT_PASSWORD = 'currentPassword'
}

export interface FormValue {
  [FormKeys.CURRENT_PASSWORD]: string;
  [NestedFormKeys.NEW_PASSWORD]: string;
  [NestedFormKeys.NEW_PASSWORD_CONFIRMATION]: string;
}

@Component({
    selector: 'ic-password-form',
    templateUrl: './password-form.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, MatFormField, MatLabel, MatInput, MatError, NewPasswordFieldsSectionComponent, MatButton, MatIcon, TranslateModule]
})
export class PasswordFormComponent implements OnInit, OnDestroy {
  @ViewChild('form', { static: true })
  public form: FormGroupDirective;

  @Output()
  public save = new EventEmitter<ChangePasswordPayload>();
  @Output()
  public changed = new EventEmitter<void>();

  public formGroup: FormGroup;
  public FormKeys = FormKeys;

  private subscriptions = new Subscription();

  public constructor(
    private formBuilder: FormBuilder,
    private passwordStrength: PasswordStrengthService
  ) {
    this.createForm();
  }

  @Input()
  public set completed(value: boolean) {
    if (!value) { return; }

    this.reset();
  }

  public ngOnInit() {
    this.subscribeToFormChanges();
  }

  public ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  public onSubmit() {
    if (this.formGroup.invalid) { return; }

    const value: FormValue = this.formGroup.value;

    this.save.emit({
      currentPassword: value[FormKeys.CURRENT_PASSWORD],
      newPassword: value[NestedFormKeys.NEW_PASSWORD]
    });
  }

  public controlHasError(controlName: FormKeys | NestedFormKeys, validatorName?: ValidationKeys) {
    const formControl = this.formGroup.get(controlName);
    const isInvalid = !!formControl.errors
      && (!validatorName || !!formControl.errors[validatorName.toLowerCase()]);

    return formControl.invalid && isInvalid && formControl.touched;
  }

  public reset() {
    this.form.resetForm();
  }

  private createForm() {
    this.formGroup = this.formBuilder.group({
      [FormKeys.CURRENT_PASSWORD]: ['', Validators.required],
      [NestedFormKeys.NEW_PASSWORD]: ['', [Validators.required, this.strongPassword.bind(this)]],
      [NestedFormKeys.NEW_PASSWORD_CONFIRMATION]: ['', [Validators.required]]
    });
  }

  private strongPassword(control: AbstractControl) {
    if (!control.value) { return null; }

    return this.passwordStrength.validate(control.value)
      ? null
      : { [ValidationKeys.STRONG_PASSWORD]: true };
  }

  private subscribeToFormChanges() {
    const subscription = this.formGroup.valueChanges
      .pipe(
        debounceTime(250)
      )
      .subscribe(() => this.changed.emit());

    this.subscriptions.add(subscription);
  }
}
