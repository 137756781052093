import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { CountryDetails } from './country-details';
import { map, shareReplay } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CountryResource {

  public constructor(
    private http: HttpClient
  ) { }

  public getCountries(filterProjects: boolean) {
    const params = { filterProjects };

    return this.http
      .get<CountryDetails[]>(`${environment.restEndpoint}/countries`, {
        params,
        headers: {
          Authorization: 'nonauthorized',
        }
      })
      .pipe(
        shareReplay()
      );
  }

  public getCountryCodes(filterProjects: boolean) {
    return this.getCountries(filterProjects)
      .pipe(
        map(countries => countries.map(
          c => c.countryCode
        ))
      );
  }
}
