import { Injectable } from '@angular/core';
import { assign } from '@shared/common';

const defaultOptions = {
  pie: {
    width: 720,
    height: 720,
    outerRadius: 290,
    innerRadius: 80,
    paddingHeight: 30,
    pullOutDistance: 30
  },
  sum: {
    fontSize: 28
  },
  icon: {
    width: 80,
    height: -80,
    distanceFromEdge: 130,
    minAngle: 23
  },
  labels: {
    fontSize: 22,
    minAngle: 0,
    labelDistance: 30
  }
};

@Injectable({
  providedIn: 'root'
})
export class PieChartLayoutServiceFactory {

  public get(options) {
    return (new PieChartLayoutService()).Layout(options);
  }
}

function PieChartLayoutService() {

  this.Layout = (options) => {
    options = assign(defaultOptions, options);

    const layout: any = {};

    layout.height = options.pie.height + options.pie.paddingHeight;
    layout.width = options.pie.width;

    const centerX = Math.floor(layout.width / 2);
    const centerY = Math.floor(layout.height / 2);

    layout.pie = {
      width: options.pie.width,
      height: options.pie.height,
      outerRadius: options.pie.outerRadius,
      innerRadius: options.pie.innerRadius,
      centerX,
      centerY,
      paddingHeight: options.pie.paddingHeight,
      pullOutDistance: options.pie.pullOutDistance
    };

    layout.sum = {
      centerX,
      centerY,
      fontSize: options.sum.fontSize,
      moneyChart: options.moneyChart
    };

    layout.icon = {
      centerX,
      centerY,
      width: options.icon.width,
      height: options.icon.height,
      outerRadius: options.pie.outerRadius,
      innerRadius: options.pie.innerRadius,
      distanceFromEdge: options.icon.distanceFromEdge,
      minAngle: options.icon.minAngle,
      pullOutDistance: options.pie.pullOutDistance
    };

    layout.labels = {
      centerX,
      centerY,
      outerRadius: options.pie.outerRadius,
      innerRadius: options.pie.innerRadius,
      fontSize: options.labels.fontSize,
      minAngle: options.labels.minAngle,
      labelDistance: options.labels.labelDistance,
      pullOutDistance: options.pie.pullOutDistance
    };

    return layout;
  };

  this.Layout.defaultOptions = defaultOptions;
}
