/* eslint-disable */
// NOTE: there's a lot of legacy code here wrapped inside the Angular service.
// Pls have in mind that even a minor refactoring can break it. Ensure you know what you're doing.
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { ChartXAxisServiceFactory } from './chart-xaxis.service';
import { ChartNodeLabelsServiceFactory } from './chart-node-labels.service';
import { ChartBarServiceFactory } from './chart-bar.service';
import { ChartLineServiceFactory } from './chart-line.service';
import { ChartAreaServiceFactory } from './chart-area.service';
import { ChartNodesServiceFactory } from './chart-nodes.service';
import { ChartYAxisServiceFactory } from './chart-yaxis.service';
import { ChartLayoutService } from './chart-layout.service';
import { ChartPointerEventsService } from './chart-pointer-events.service';
import { ChartYearSeparatorsServiceFactory } from './chart-year-separators.service';
import { AvailableDimensionsArgs, ChartData, ChartOptions, CoordsUpdateArgs } from '../models';

@Injectable()
export class ChartDrawService {
  private MINIMUM_DATA_POINT_MAX = 1;
  private data: ChartData;
  private lastBarXposition: number = null;
  private eventHandler: any;
  private eventHandlersInitialized = false;
  private svgElement: SVGElement;
  private layout: any;
  private intervals: any;
  private selection: any;
  private currentDate: Date;
  private bar: any;
  private nodes: any;
  private nodeLabels: any;
  private xAxis:any;
  private yAxis:any;
  private line:any;
  private averageLine:any;
  private area:any;
  private averageArea:any;
  private yearSeparators:any;

  constructor(
    private chartAxisXFactory: ChartXAxisServiceFactory,
    private chartAxisYFactory: ChartYAxisServiceFactory,
    private chartNodeLabelsServiceFactory: ChartNodeLabelsServiceFactory,
    private chartBarServiceFactory: ChartBarServiceFactory,
    private chartLine: ChartLineServiceFactory,
    private chartAreaFactory: ChartAreaServiceFactory,
    private chartNodesServiceFactory: ChartNodesServiceFactory,
    private chartYearSeparatorFactory: ChartYearSeparatorsServiceFactory,
    private chartLayout: ChartLayoutService,
    private chartPointerEvents: ChartPointerEventsService
  ) { }

  createChart(options: ChartOptions) {
    let numberFormatter = options.numberFormatter || d3.functor;
    const ratioLimits = { min: 1.8, max: 3.4 };

    this.xAxis = this.chartAxisXFactory.get();
    this.nodeLabels = this.chartNodeLabelsServiceFactory.get().formatter(numberFormatter);
    this.bar = this.chartBarServiceFactory.get();
    this.line = this.chartLine.get('line-container');
    this.averageLine = this.chartLine.get('averageLine-container');
    this.area = this.chartAreaFactory.get('area-container');
    this.averageArea = this.chartAreaFactory.get('averageArea-container');
    this.nodes = this.chartNodesServiceFactory.get();
    this.yAxis = this.chartAxisYFactory.get().formatter(numberFormatter);
    this.yearSeparators = this.chartYearSeparatorFactory.get();

    const setCurrentDate = (xPosition, onChange) => {
      const activeDate = this.getCurrentDate(this.intervals, this.layout, xPosition);

      if (!this.currentDate || this.currentDate.getMonth() !== activeDate.getMonth()) {
        this.currentDate = activeDate;
        onChange();
      }
    };

    const updateCurrentDate = () => {
      window.requestAnimationFrame(() => {
        this.selection
          .call(this.xAxis.updateActive, this.currentDate)
          .call(this.nodeLabels.updateActive, this.currentDate)
          .call(this.nodes.updateActive, this.currentDate);
      });
    };

    const onXPositionUpdate = (event: CustomEvent<CoordsUpdateArgs>) => {
      const xPosition = event.detail.nearestTickPosition;
      window.requestAnimationFrame(() => {
        this.selection.call(this.bar.updateXPosition, xPosition);
      });
      setCurrentDate(xPosition, updateCurrentDate);
      this.lastBarXposition = xPosition;
    };

    const onDetermineBarSnapPosition = (xPosition: number) => {
      const xPositionOfNearestTick: number = this.getXPositionOfNearestTick(
        this.intervals,
        this.layout,
        xPosition
      );

      if (xPositionOfNearestTick) {
        this.svgElement.dispatchEvent(
          new CustomEvent<CoordsUpdateArgs>('xPositionUpdate', { detail: { nearestTickPosition: xPositionOfNearestTick } })
        );
      }
    };

    const adjustChartDimensions = (
      availableDimensions,
      numberOfIntervals
    ) => {
      const heightPerChart = availableDimensions.height / numberOfIntervals;
      const ratio = Math.max(
        ratioLimits.min,
        Math.min(ratioLimits.max, availableDimensions.width / heightPerChart)
      );

      options.chart = Object.assign(
        {},
        this.chartLayout.defaultOptions.chart,
        options.chart
      );
      options.chart.height = options.chart.width / ratio;
    };

    const onAvailableDimensionsUpdate = (event: CustomEvent<AvailableDimensionsArgs>) => {
      adjustChartDimensions(event.detail, this.intervals.length);
      updateAllElements();
    };

    const setupEvents = () => {
      this.svgElement.addEventListener(
        'xPositionUpdate', onXPositionUpdate
      );
      this.svgElement.addEventListener(
        'determineBarSnapPosition',
        (event: CustomEvent<CoordsUpdateArgs>) => onDetermineBarSnapPosition(event.detail.nearestTickPosition)
      );
      this.svgElement.addEventListener(
        'availableDimensionsUpdate', onAvailableDimensionsUpdate
      );
    };

    const setInitialBarPosition = () => {
      if (!this.lastBarXposition) {
        const lastDataPoint: any = this.intervals[0].dataPoints.at(-1);

        this.lastBarXposition = this.intervals[0].xScale(lastDataPoint.date) + this.layout.line.x;
      }

      onDetermineBarSnapPosition(this.lastBarXposition);
    };

    const appendTo = (element: SVGElement, data: ChartData, resetBarPosition?: boolean) => {
      this.data = Object.assign({}, data);

      if (!this.svgElement)
        this.svgElement = element;

      this.selection = d3.select(element);

      if (!this.eventHandler)
        this.eventHandler = this.chartPointerEvents.eventHandler(this.svgElement, this.layout);
      else
        this.eventHandler.setLayout(this.layout);

      adjustChartDimensions(
        this.eventHandler.getAvailableDimensions(),
        this.data.intervals.length
      );

      const updateOptions = { resetBarPosition };
      update(updateOptions);

      if (!this.eventHandlersInitialized) {
        setupEvents();
        this.eventHandlersInitialized = true;
      }
      setInitialBarPosition();
      this.eventHandler.activate();
    };

    const updateAllElements = () => {
      this.layout = this.chartLayout.layout(this.data.intervals.length, options);
      this.intervals = this.buildIntervals(this.data, this.layout);

      const subsets = this.generateDataPointSubsets(this.data.dataPoints);
      let averageSubsets = [];

      if (this.data.showAverage) {
        averageSubsets = this.generateDataPointSubsets(this.data.averageDataPoints);
      }

      this.svgElement.setAttribute('viewBox', '0 0 ' + this.layout.width + ' ' + this.layout.height);
      this.xAxis.intervals(this.intervals).layout(this.layout.xAxis);
      this.nodeLabels.intervals(this.intervals).layout(this.layout.nodeLabels);
      this.bar.layout(this.layout.bar);
      this.line.layout(this.layout.line).intervals(this.intervals).subsets(subsets);
      this.averageLine
        .layout(this.layout.line)
        .intervals(this.intervals)
        .subsets(averageSubsets);
      this.nodes.layout(this.layout.nodes).intervals(this.intervals);
      this.area.layout(this.layout.area).intervals(this.intervals).subsets(subsets);
      this.averageArea
        .layout(this.layout.area)
        .intervals(this.intervals)
        .subsets(averageSubsets);
        this.yAxis.layout(this.layout.yAxis).intervals(this.intervals);

        this.yearSeparators.layout(this.layout.yearSeparators).intervals(this.intervals);

      window.requestAnimationFrame(() => {
        this.selection
          .call(this.yearSeparators)
          .call(this.averageArea)
          .call(this.area)
          .call(this.yAxis)
          .call(this.averageLine)
          .call(this.line)
          .call(this.nodes)
          .call(this.bar)
          .call(this.xAxis)
          .call(this.nodeLabels);
      });

      this.eventHandler.setLayout(this.layout);
    }

    const processUpdateOptions = (updateOptions) => {
      if (!updateOptions) {
        return;
      }

      if (updateOptions.resetBarPosition) {
        this.lastBarXposition = null;
      }

      if (updateOptions.numberFormatter) {
        numberFormatter = updateOptions.numberFormatter || d3.functor;
        this.nodeLabels = this.chartNodeLabelsServiceFactory.get().formatter(numberFormatter);
        this.yAxis = this.chartAxisYFactory.get().formatter(numberFormatter);
      }
    };

    const update = (updateOptions) => {
      processUpdateOptions(updateOptions);
      updateAllElements();
      this.currentDate = null;
      setInitialBarPosition();
    };

    const getIntervals = () => {
      return this.intervals;
    };

    const updateData = (data: ChartData) => {
      this.data = Object.assign({ }, data);
    };

    return { getIntervals, update, updateData, appendTo };
  }

  private getMaxValueFromDataPoints(data) {
    let maxDataPointValue = d3.max(data.dataPoints, function (d) {
      return d.value;
    });

    // check if averageDataPoints has new max value and override maxDataPointValue
    if (data.averageDataPoints && data.showAverage) {
      const maxAverageDataPointValue = d3.max(
        data.averageDataPoints,
        function (d) {
          return d.value;
        }
      );

      if (
        maxAverageDataPointValue &&
        maxAverageDataPointValue > maxDataPointValue
      ) {
        maxDataPointValue = maxAverageDataPointValue;
      }
    }

    return maxDataPointValue;
  }

  private calculateYLabels(data) {
    const dataPoints = data.dataPoints;

    if (!dataPoints || dataPoints.length < 1) {
      return [];
    }

    let maxi = this.getMaxValueFromDataPoints(data);

    if (maxi < this.MINIMUM_DATA_POINT_MAX) {
      maxi = this.MINIMUM_DATA_POINT_MAX;
    }

    return [maxi * 0.1, maxi * 0.5, maxi * 0.9];
  }

  private buildIntervals(data, layout) {
    let maxDataPointValue = this.getMaxValueFromDataPoints(data);

    if (maxDataPointValue < this.MINIMUM_DATA_POINT_MAX) {
      maxDataPointValue = this.MINIMUM_DATA_POINT_MAX;
    }

    const yScale = d3.scale
      .linear()
      .range([layout.line.height, 0])
      .domain([0, maxDataPointValue]);
    const yLabels = this.calculateYLabels(data);

    const intervals = [];

    data.intervals.forEach((interval, index) => {
      const xScale = d3.time
        .scale()
        .range([0, layout.line.width])
        .domain([interval.from, interval.to]);

      const line = d3.svg
        .line()
        .x(function (dataPoint) {
          return xScale(dataPoint.date);
        })
        .y(function (dataPoint) {
          return yScale(dataPoint.value);
        })
        .interpolate('linear');

      const area = d3.svg
        .area()
        .x(function (dataPoint) {
          return xScale(dataPoint.date);
        })
        .y0(yScale(0))
        .y1(function (dataPoint) {
          return yScale(dataPoint.value);
        })
        .interpolate('linear');

      intervals.push({
        from: interval.from,
        to: interval.to,
        xScale: xScale,
        yScale: yScale,
        yLabels: yLabels,
        y: layout.intervals[index].y,
        dataPoints: this.getDataPointsInInterval(data.dataPoints, interval),
        averageDataPoints: this.getDataPointsInInterval(
          data.averageDataPoints,
          interval
        ),
        line: line,
        area: area,
      });
    });

    return intervals;
  }

  private getDataPointsInInterval(dataPoints, interval) {
    const dataPointsInInterval = [];

    dataPoints.forEach((dataPoint) => {
      const momentDate = moment(dataPoint.date);
      const fromRange = moment(interval.from);
      const toRange = moment(interval.to);

      if (
        (momentDate.isAfter(fromRange) && momentDate.isBefore(toRange)) ||
        momentDate.isSame(fromRange) ||
        momentDate.isSame(toRange)
      ) {
        dataPointsInInterval.push(dataPoint);
      }
    });

    return dataPointsInInterval;
  }

  private generateSubset(dataPoints, startIndex) {
    const subset = [];

    for (let index = startIndex; index < dataPoints.length; index++) {
      if (
        subset.length > 0 &&
        Math.abs(
          moment(subset.at(-1).date).diff(dataPoints[index].date, 'months')
        ) > 1
      ) {
        break;
      }
      subset.push(dataPoints[index]);
    }

    return subset;
  }

  private generateDataPointSubsets(dataPoints) {
    const subsets = [];
    let startIndex = 0;

    while (startIndex < dataPoints.length) {
      const subset = this.generateSubset(dataPoints, startIndex);

      subsets.push(subset);
      startIndex = dataPoints.indexOf(subset.at(-1)) + 1;
    }

    return subsets;
  }

  private determineActiveMonthDate(xScale, layout, xPosition) {
    return d3.time.month.round(
      xScale.invert(
        Math.max(0, Math.min(xScale.range()[1], xPosition - layout.line.x))
      )
    );
  }

  private getCurrentDate(intervals, layout, xPosition, intervalIndex = 0) {
    if (intervals.length < 1) {
      return;
    }

    intervalIndex = intervalIndex || 0;
    const interval = intervals[intervalIndex];

    return this.determineActiveMonthDate(interval.xScale, layout, xPosition);
  }

  private getXPositionOfNearestTick(intervals, layout, xPosition) {
    if (intervals.length < 1) {
      return 0;
    }
    const interval = intervals[0];
    const activeDate = this.determineActiveMonthDate(
      interval.xScale,
      layout,
      xPosition
    );

    return interval.xScale(activeDate) + layout.line.x;
  }

}
