import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { CurrentUserService } from '../services';
import { KeycloakService } from 'keycloak-angular';

export const ensureTosAccepted = async () => {
  const currentUser = inject(CurrentUserService);
  const router = inject(Router);
  const keycloak = inject(KeycloakService);

  if (!keycloak.isLoggedIn()) {
    return true;
  }

  const user = await firstValueFrom(currentUser.ensureUser$);

  if (!user?.termsAccepted) {
    setTimeout(() => {
      router.navigate(['/terms']);
    });
  }

  return user.termsAccepted;
}
