<div *ngIf="!accepted" class="cookies">
	<div class="alert-warning flex flex-col gap-2">
		<div>
      <ng-container ngInclude></ng-container>
    </div>

		<div>
      <a [routerLink]="['/cookie-policy']">
        {{ 'COOKIE_POLICY.SHOW_BUTTON' | translate }}
      </a>
    </div>

		<div class="flex justify-end">
      <button type="button" mat-flat-button color="accent"
              (click)="acceptCookiePolicy()">
        {{ 'ACCEPT' | translate }}
      </button>
		</div>
	</div>
</div>
