/* eslint-disable */
// NOTE: there's a lot of legacy code here wrapped inside the Angular service.
// Pls have in mind that even a minor refactoring can break it. Ensure you know what you're doing.
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ChartNodesServiceFactory {
  get() {
    return (new LegacyChartNodesService()).nodes();
  }
}

function LegacyChartNodesService() {
  this.nodes = function () {
    let intervals, layout;
    let selectionCache;

    const circles = function (selection) {
      selection.each(function (interval) {
        const circle = d3
          .select(this)
          .selectAll('.node')
          .data(interval.dataPoints);

        circle.exit().remove();
        circle.enter().append('circle').attr('class', 'node');
        circle.transition().attr({
          cx: function (dataPoint) {
            return interval.xScale(dataPoint.date);
          },
          cy: function (dataPoint) {
            return interval.yScale(dataPoint.value);
          },
          r: layout.radius,
        });
      });

      selectionCache = selection.selectAll('.node');
    };

    const groups = function (selection) {
      selection.each(function () {
        const g = d3.select(this).selectAll('.nodes').data(intervals);

        g.exit().remove();
        g.enter().append('g').attr('class', 'nodes');
        g.attr('transform', function (interval) {
          return 'translate(' + layout.x + ', ' + (layout.y + interval.y) + ')';
        });
        g.call(circles);
      });
    };

    groups.updateActive = function (selection, currentDate) {
      selectionCache.classed('active', function (dataPoint) {
        return dataPoint.date.getMonth() === currentDate.getMonth();
      });
    };

    groups.intervals = function (_) {
      if (!arguments.length) {
        return intervals;
      }
      intervals = _;

      return groups;
    };

    groups.layout = function (_) {
      if (!arguments.length) {
        return layout;
      }
      layout = _;

      return groups;
    };

    return groups;
  };
}
