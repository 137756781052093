export interface SeriesDataConversionPayload<TValue> {
  [date: string]: TValue;
}

export enum ValueType {
  STANDARD = 'STANDARD',
  ESTIMATED = 'ESTIMATED',
}

export interface SeriesData<TValue> {
  date: Date;
  valueType?: ValueType;
  value: TValue;
}
