import { Injectable } from '@angular/core';
import * as Hammer from 'hammerjs';
import { AvailableDimensionsArgs, CoordsUpdateArgs } from '../models';

@Injectable({
  providedIn: 'root'
})
export class ChartPointerEventsService {
  public eventHandler(svgElement: SVGElement, layout) {
    const svgSelection = d3.select(svgElement);
    let containerWidth; let svgOffset;

    const domainEventTrigger = {
      xPositionUpdate: (xPosition) => {
        if (!isNaN(xPosition)) {
          svgElement.dispatchEvent(
            new CustomEvent<CoordsUpdateArgs>('xPositionUpdate', { detail: { nearestTickPosition: xPosition } })
          );
        }
      },
      determineBarSnapPosition: (xPosition) => {
        if (!isNaN(xPosition)) {
          svgElement.dispatchEvent(
            new CustomEvent<CoordsUpdateArgs>('determineBarSnapPosition', { detail: { nearestTickPosition: xPosition } })
          );
        }
      },
      availableDimensionsUpdate: (availableDimensions: any) => {
        svgElement.dispatchEvent(
          new CustomEvent<AvailableDimensionsArgs>('availableDimensionsUpdate', { detail: availableDimensions })
        );
      },
    };

    const calculators = {
      relativeX: (absoluteX) => ((absoluteX - svgOffset.left) / containerWidth) * layout.width,
      svgHeight: () => (containerWidth / layout.width) * layout.height,
    };

    const eventHandler = {
      onMouseAction: () => {
        // note: we don't use d3.mouse as this method returns incorrect values on windows mobile 8.1
        const x: number = calculators.relativeX(d3.event.clientX);

        domainEventTrigger.determineBarSnapPosition(x);
      },
      onDrag: (event) => {
        const x = calculators.relativeX(event.center.x);

        domainEventTrigger.xPositionUpdate(x);
      },
      onDragEnd: (event) => {
        const x = calculators.relativeX(event.center.x);

        domainEventTrigger.determineBarSnapPosition(x);
      },
      onResize: () => {
        const availableDimensions = getAvailableDimensions();

        containerWidth = availableDimensions.width;
        svgOffset = availableDimensions.offset;
      },
    };

    let resizeEvent: any = undefined;

    const eventSetup = {

      d3MouseListener: () => {
        svgSelection.on('click', eventHandler.onMouseAction);
      },
      hammer: () => {
        const hammerTime = new Hammer(svgElement);
        hammerTime.on('pan', eventHandler.onDrag);
        hammerTime.on('panend', eventHandler.onDragEnd);
      },
      svgScaling: () => {
        const handler = () => {
          if (resizeEvent)
            clearTimeout(resizeEvent);

          resizeEvent = setTimeout(() => {
            eventHandler.onResize();
          }, 750)
        };

        window.addEventListener('resize', handler);

        setTimeout(() => {
          eventHandler.onResize();
        }, 250);
      },
    };

    const setLayout = (newLayout) => {
      layout = newLayout;
      // if (containerWidth) {
      //   svgElement.setAttribute('height', calculators.svgHeight().toString());
      // }
    };

    const activate = () => {
      eventSetup.d3MouseListener();
      eventSetup.hammer();
      eventSetup.svgScaling();
    };

    const getAvailableDimensions = () => {
      const parentNode = (svgElement.parentElement.parentNode as HTMLElement);
      const parentBoundingRect = parentNode.getBoundingClientRect();

      const offset = {
        top: parentBoundingRect.top + window.scrollY,
        left: parentBoundingRect.left + window.scrollX
      };

      const width = parentNode.clientWidth;

      if (width === 0) {
        // fallback if parent is not visible
        // TODO: check if required
        // width = $('div[ui-view=\'content\']').width();
      }

      return {
        width,
        height: window.innerHeight - parentNode.offsetTop,
        offset,
      };
    };

    return { getAvailableDimensions, activate, setLayout };
  }
}
