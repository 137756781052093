<ng-container *ngIf="locale$ | async as locale">
  <button mat-icon-button [matMenuTriggerFor]="languageSelector">
    <img [src]="'assets/icons/flags/' + locale.lang + '.png'" />
  </button>
  <mat-menu #languageSelector="matMenu" class="flex-auto gt-xs:pl-3">
    <button
      *ngFor="let localeString of allowedLocaleStrings"
      mat-menu-item
      [ngClass]="locale.localeString === localeString ? 'bg-primary-500/5' : ''"
      (click)="onChangeLanguage(localeString)"
    >
      <img class="w-6 mr-2" src="{{ 'assets/icons/flags/' + languageFromLocale(localeString) + '.png' }}" />
      <div [ngClass]="locale.localeString === localeString ? 'text-primary' : ''">
        {{ "LANG_" + localeString.toUpperCase() | translate }}
      </div>
    </button>
  </mat-menu>
</ng-container>

<button *ngIf="isAuthorized$ | async" mat-icon-button [matMenuTriggerFor]="accountMenu">
  <mat-icon class="icon-size-6">account_circle</mat-icon>
</button>
<mat-menu #accountMenu="matMenu" class="flex-auto gt-xs:pl-3">
  <ng-container *ngIf="isChangePasswordEnabled$ | async">
    <button mat-menu-item [routerLink]="['/password']">
      <mat-icon class="icon-size-6">lock</mat-icon>
      {{ "CHANGE_PASSWORD" | translate }}
    </button>
  </ng-container>

  <ng-container *ngIf="isChangeEmailEnabled$ | async">
    <button mat-menu-item [routerLink]="['/email']">
      <mat-icon class="icon-size-6">mail</mat-icon>
      {{ "CHANGE_EMAIL" | translate }}
    </button>

    <button mat-menu-item [routerLink]="['/user/settings']">
      <mat-icon class="icon-size-6">settings</mat-icon>
      {{ "SETTINGS" | translate }}
    </button>
  </ng-container>

  <button mat-menu-item [routerLink]="['/user/settings/mfa']">
    <mat-icon class="icon-size-6">admin_panel_settings</mat-icon>
    {{ "SETTINGS_MFA.MENU_TITLE" | translate }}
  </button>

  <button mat-menu-item [routerLink]="['/logout']">
    <mat-icon class="icon-size-6 text-warn-500">logout</mat-icon>
    <span class="text-warn-500">
      {{ "LOGOUT" | translate }}
    </span>
  </button>
</mat-menu>
