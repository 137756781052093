@if (isAuthorized$ | async) {
  @if (consumptionEnabled$ | async) {
    <div class="ista-vertical-navigation-item py-1">
      <a mat-button [routerLink]="['/user/consumption']" routerLinkActive="active" class="text-sm font-normal">
        <mat-icon class="icon-size-6">monitoring</mat-icon>
        {{ "NAVIGATION_CONSUMPTIONS" | translate }}
      </a>
    </div>
  }

  @if (documentsEnabled$ | async) {
    <div class="ista-vertical-navigation-item py-1">
      <a mat-button [routerLink]="['/user/document']" routerLinkActive="active" class="text-sm font-normal">
        <mat-icon class="icon-size-6">description</mat-icon>
        {{ "NAVIGATION_DOCUMENTS" | translate }}
      </a>
    </div>
  }
} @else {
  <div class="ista-vertical-navigation-item py-1">
    <a mat-button [routerLink]="['/welcome']" class="text-sm font-normal">
      <mat-icon class="icon-size-6">home</mat-icon>
      {{ "HOME" | translate }}
    </a>
  </div>
}
