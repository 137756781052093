import { Injectable } from '@angular/core';
import { UserRole } from '../models';

@Injectable({ providedIn: 'root' })
export class RoleManagerService {
  public isAnonymous(anonymous: any[]) {
    return !anonymous?.length;
  }

  public isUser(user: any) {
    return this.hasRole(user, UserRole.PM);
  }

  public isAdmin(user: any) {
    return this.hasRole(user, UserRole.ADMIN);
  }

  public isSuperAdmin(user: any) {
    return this.hasRole(user, UserRole.SUPER_ADMIN);
  }

  public hasRole(user: any, role: any) {
    if (!!user?.role) {
      if (user.role === role) {
        return true;
      }
    }

    return false;
  }
}
