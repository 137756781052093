import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, debounceTime, filter, map, Subscription, tap } from 'rxjs';

const DURATION = 350;

@Injectable({ providedIn: 'root' })
export class SpinnerService {
  private pending = 0;
  private visible = false;

  private visibilityPipeline$ = new BehaviorSubject(false);
  private subscriptions = new Subscription();

  public constructor(
    private spinners: NgxSpinnerService
  ) {
    this.flushSubscriptions();

    this.subscribeToPushActions();
    this.subscribeToPopActions();
  }

  public push() {
    this.visibilityPipeline$.next(true);
  }

  public pop() {
    this.visibilityPipeline$.next(false);
  }

  public cancel() {
    this.pending = 0;
    this.markAsHidden();
  }

  private flushSubscriptions() {
    this.subscriptions.unsubscribe();
    this.subscriptions = new Subscription();
  }

  private subscribeToPushActions() {
    const subscription = this.visibilityPipeline$.asObservable()
      .pipe(
        filter(visible => visible)
      )
      .subscribe(() => {
        this.pending += 1;

        if (!this.visible && this.pending > 0) { this.markAsVisible(); }
      });

    this.subscriptions.add(subscription);
  }

  private subscribeToPopActions() {
    const subscription = this.visibilityPipeline$.asObservable()
      .pipe(
        filter(visible => !visible),
        tap(() => {
          this.pending = Math.max(this.pending - 1, 0);
        }),
        debounceTime(DURATION),
        map(() => !this.pending),
        filter(shouldHide => shouldHide),
      )
      .subscribe(() => {
        this.markAsHidden();
      });

    this.subscriptions.add(subscription);
  }

  private markAsVisible() {
    this.spinners.show();
  }

  private markAsHidden() {
    this.spinners.hide();
  }
}
