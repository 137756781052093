import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class DateConverterService {
  public convertDateStringToDateObject(dateString) {
    return moment(dateString).toDate();
  }

  public convertDateStringToDateObjectFirstDayOfMonth(dateString) {
    return moment(dateString)
      .startOf('month')
      .startOf('day')
      .toDate();
  }

  public convertDateStringToDateObjectLastDayOfMonth(dateString) {
    return moment(dateString)
      .endOf('month')
      .startOf('day')
      .toDate();
  }

  public convertDateToIsoString(date: Date) {
    return moment(date).format('YYYY-MM-DD');
  }
}
