import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PieChartPointerEventsServiceFactory {

  public get(svg, layout) {
    return (new PieChartPointerEventsService()).EventHandler(svg, layout);
  }
}

function PieChartPointerEventsService() {

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  this.EventHandler = function(svg: HTMLElement, layout) {
    const svgSelection = d3.select(svg);
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let containerWidth;

    const domainEventTrigger = {
      roomActiveOnClick: (roomId) => {
        if (typeof roomId !== 'undefined') {
          const roomTransformationOptions = { roomId, pullOutPieSegment: true };

          // TODO: adjust the payload to match handler args
          svg.dispatchEvent(
            new CustomEvent('roomActiveInPie', { detail: roomTransformationOptions })
          );
        }
      }
    };

    const eventHandler = {
      onMouseAction: () => {
        const selectedElement = d3.event.target;
        let roomId;

        // eslint-disable-next-line no-underscore-dangle
        if (!!selectedElement.__data__) {
          // eslint-disable-next-line no-underscore-dangle
          roomId = selectedElement.__data__.data.uuid;
          domainEventTrigger.roomActiveOnClick(roomId);
        }
      },
      onResize: () => {
        containerWidth = that.getAvailableDimensions().width;
      }
    };

    const eventSetup = {
      d3MouseListener: () => {
        svgSelection.select('g.pie-slice-group').on('click', eventHandler.onMouseAction);
        svgSelection.select('g.pie-label-group').on('click', eventHandler.onMouseAction);
        svgSelection.select('g.pie-icon-group').on('click', eventHandler.onMouseAction);
      },
      svgScaling: () => {
        window.addEventListener('resize', eventHandler.onResize); // TODO: check if remove needed
        eventHandler.onResize();
        setTimeout(() => {
          eventHandler.onResize();
        }, 250);
      }
    };

    this.setLayout = (newLayout) => {
      layout = newLayout;
    };

    this.activate = () => {
      eventSetup.d3MouseListener();
      eventSetup.svgScaling();
    };

    this.getAvailableDimensions = () => {
      const parentNode = (svg.parentElement.parentNode as HTMLElement);
      const parentBoundingRect = parentNode.getBoundingClientRect();

      const offset = {
        top: parentBoundingRect.top + window.scrollY,
        left: parentBoundingRect.left + window.scrollX
      };

      const width = parentNode.clientWidth;

      if (width === 0) {
        console.log('fallback TODO'); // TODO
        // fallback if parent is not visible
        // TODO: check if required
        // width = $('div[ui-view=\'content\']').width();
      }

      return {
        width,
        height: window.innerHeight - parentNode.offsetTop,
        offset,
      };
    };

    return this;
  };
}
