import { Component, Input } from '@angular/core';
import { BreadcrumbConfiguration, BreadcrumbItem } from '../../models/breadcrumb.model';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NgFor, NgIf } from '@angular/common';

@Component({
    selector: 'ic-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    standalone: true,
    imports: [
        NgFor,
        NgIf,
        TranslateModule,
    ],
})
export class BreadcrumbComponent {
  @Input()
  public configuration: BreadcrumbConfiguration = [];

  public constructor(
    private router: Router
  ) {
  }

  public handleNavigation(item: BreadcrumbItem) {
    this.router.navigate(item.routerLink);
  }
}
