import { Injectable } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { combineLatest, first, from, map, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TranslationLoadingService implements TranslateLoader {
  public getTranslation(lang: string): Observable<any> {
    return this.loadMultiple([
      `analytics/${lang}.json`,
      `tenant/${lang}.json`,
      `admin/en-gb.json`
    ]).pipe(
      first(),
      map(results => {
        const [analytics, tenant, admin] = results;
        return {
          ...analytics,
          ...tenant,
          ADMIN: { ...admin }
        };
      })
    );
  }

  protected loadMultiple(paths: string[]) {
    return combineLatest([
      ...paths.map(p => this.load(p))
    ]);
  }

  private load(path: string) {
    return from(
      import(`src/assets/i18n/${path}`)
    );
  }
}
