import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { TranslateModule } from '@ngx-translate/core';
import { RouterLink } from '@angular/router';
import { MatButton } from '@angular/material/button';
import { FuseAlertComponent } from '@ista-ui/angular';

export interface AlertInfo {
  showRecoveryLink?: boolean;
  recoveryLink?: string;
  recoveryText?: string;
  type: 'primary' | 'accent' | 'warn' | 'basic' | 'info' | 'success' | 'warning' | 'error';
  msgCode: string;
  message?: string;
  translationData?: any;
}

@Component({
    selector: 'ic-alert-box',
    template: `
    @if (!!localAlert) {
      <fuse-alert dismissible="false" appearance="outline" [type]="localAlert.type"
                  class="mb-8 max-w-2xl mx-auto">
        @if (!!localAlert.message) {
          {{ localAlert.message }}
        } @else {
          {{ localAlert.msgCode | translate : localAlert.translationData }}
        }

        @if (localAlert?.showRecoveryLink) {
          <div class="flex mt-2">
            <button mat-stroked-button
                    [routerLink]="[ localAlert.recoveryLink ]"
                    [color]="buttonColor"
                    class="flex-1 md:flex-none">
              {{ localAlert.recoveryText | translate }}
            </button>
          </div>
        }
      </fuse-alert>
    }
  `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [FuseAlertComponent, MatButton, RouterLink, TranslateModule]
})
export class AlertBoxComponent {
  @Input()
  public fade = false;

  public localAlert: AlertInfo;

  private alertInfoData: AlertInfo;

  public constructor(
    private changeDetector: ChangeDetectorRef
  ) {
  }

  public get buttonColor(): ThemePalette | null {
    switch (this.localAlert?.type) {
      case 'primary': {
        return 'primary';
      }
      case 'warn':
      case 'error': {
        return 'warn';
      }
      case 'success':
      case 'accent': {
        return 'accent';
      }
      default: {
        return null;
      }
    }
  }

  @Input()
  public get alertInfo() {
    return this.alertInfoData;
  }

  public set alertInfo(info: AlertInfo) {
    this.alertInfoData = info;
    this.setupAlert(info);
  }

  private setupAlert(alertInfo: AlertInfo) {
    if (!alertInfo) {
      this.localAlert = null;
      return;
    }

    if (alertInfo.showRecoveryLink) {
      if (!alertInfo.recoveryLink) {
        throw new Error('No recoveryLink is provided!');
      }
      if (!alertInfo.recoveryText) {
        throw new Error('No recoveryText is provided!');
      }
    }

    this.localAlert = Object.assign({}, alertInfo);

    this.changeDetector.markForCheck();
  }

}
