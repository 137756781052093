import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    templateUrl: './notice_de.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true
})
export class NoticeDEComponent { }

@Component({
    templateUrl: './notice_en.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true
})
export class NoticeENComponent { }

@Component({
    templateUrl: './notice_hr.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true
})
export class NoticeHRComponent { }

@Component({
    templateUrl: './notice_it.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true
})
export class NoticeITComponent { }

@Component({
    templateUrl: './notice_pl.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true
})
export class NoticePLComponent { }

@Component({
    templateUrl: './notice_ro.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true
})
export class NoticeROComponent { }

