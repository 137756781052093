import { animate, style, transition, trigger } from '@angular/animations';
import { BreakpointObserver } from '@angular/cdk/layout';
import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, inject, Input, OnDestroy, signal } from '@angular/core';
import { MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatToolbar } from '@angular/material/toolbar';
import { RouterLink } from '@angular/router';
import { FuseDrawerComponent } from '@ista-ui/angular';
import { icBrand } from '@shared/branding';
import { AcceptCookiesComponent } from '@shared/disclaimer';
import { AppFooterComponent } from '@shared/layout/components/app-footer';
import { BreadcrumbComponent } from '@shared/layout/components/breadcrumb/breadcrumb.component';
import { MainNavigationComponent } from '@shared/layout/components/main-navigation/main-navigation.component';
import { SpinnerComponent } from '@shared/layout/components/spinner';
import { TopNavigationComponent } from '@shared/layout/components/top-navigation';
import { FallbackImageSourceDirective } from '@shared/layout/directives/fallback-image-source';
import { UiBreakpoints } from '@shared/layout/enums';
import { BreadcrumbConfiguration } from '@shared/layout/models/breadcrumb.model';
import { LanguageSelectionService } from '@shared/localization';
import { InlineNotificationComponent, ToastsComponent } from '@shared/notifications';
import { delay, map, Observable, Subscription, tap } from 'rxjs';
import { DefaultLayoutService } from './default-layout.service';

@Component({
  selector: 'ic-default-layout',
  templateUrl: './default-layout.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  animations: [
    trigger('enterAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('0.6s cubic-bezier(.5,0,0,1)', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        animate('0.2s cubic-bezier(.5,0,0,1)', style({ opacity: 0 }))
      ]),
    ])
  ],
  imports: [FuseDrawerComponent, RouterLink, FallbackImageSourceDirective, MainNavigationComponent, MatToolbar, MatIconButton, MatIcon, TopNavigationComponent, BreadcrumbComponent, InlineNotificationComponent, AppFooterComponent, AcceptCookiesComponent, SpinnerComponent, ToastsComponent, AsyncPipe]
})
export class DefaultLayoutComponent implements OnDestroy {

  @HostBinding('class')
  public cssClass = 'ista-ui-layout';

  @Input()
  public breadcrumb: BreadcrumbConfiguration;

  public readonly expanded = signal<boolean>(true);
  public readonly brandName = inject(icBrand);
  public readonly isBranded = !!this.brandName;
  public readonly brandLogo = this.isBranded
    ? `/s2/brands/${this.brandName}/logo_1.png`
    : '/assets/img/ista_Logo_horizontal_Connect_OceanBlue_RGB.svg';
  public readonly assetsRev = +new Date();

  public isSmallScreen$: Observable<boolean>;
  public locale$ = this.languageSelection.locale$;
  private subscriptions: Subscription = new Subscription();

  public constructor(
    private breakpointObserver: BreakpointObserver,
    private languageSelection: LanguageSelectionService,
    private defaultLayoutService: DefaultLayoutService
  ) {
    this.subscribeToBreakpointChanges();
    this.subscribeToExpandedChanges();

  }

  public toggleDrawer() {
    this.expanded.update(expanded => !expanded);
    this.defaultLayoutService.setUserState(this.expanded());
  }

  public onDrawerToggled(open: boolean) {
    if (this.expanded() !== open) {
      this.expanded.set(open);
    }
  }

  private subscribeToBreakpointChanges() {
    this.isSmallScreen$ = this.breakpointObserver
      .observe([UiBreakpoints.XS, UiBreakpoints.SM, UiBreakpoints.MD])
      .pipe(
        map(({ matches }) => matches),
        delay(100),
        tap(isSmallScreen => {
          if (!isSmallScreen) {
            return;
          }

          this.expanded.set(false);
        })
      );
  }

  private subscribeToExpandedChanges() {
    this.subscriptions.add(this.defaultLayoutService.expanded$.subscribe((value: boolean) => {
      this.expanded.set(value);
    }));
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

}
