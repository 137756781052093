/* eslint-disable */
// NOTE: there's a lot of legacy code here wrapped inside the Angular service.
// Pls have in mind that even a minor refactoring can break it. Ensure you know what you're doing.
import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({ providedIn: 'root' })
export class ChartYearSeparatorsServiceFactory {
  get() {
    return (new LegacyChartYearSeparatorsService()).yearSeperators();
  }
}

function LegacyChartYearSeparatorsService() {
  const calculateYearSeparatorsData = function (interval, layout) {
    const from = moment(interval.from);
    const to = moment(interval.to);

    const separators = [];

    if (from.year() === to.year()) {
      separators.push({
        label: from.year(),
        type: 'start',
        x1: 0 - layout.x,
        renderLabel: true
      });
    } else {
      separators.push({
        label: from.year(),
        type: 'end',
        x1: interval.xScale(new Date(from.year(), 11, 16)) - layout.width,
        renderLabel: from.isBefore(new Date(from.year(), 11, 1))
      });
      separators.push({
        label: to.year(),
        type: 'start',
        x1: interval.xScale(new Date(from.year(), 11, 16)),
        renderLabel: to.isAfter(new Date(to.year(), 0, 31))
      });
    }

    return separators;
  };

  this.yearSeperators = function () {
    let intervals, layout, initialized;

    const initGradient = function (svg) {
      const gradient = svg.append('svg:defs')
        .append('svg:linearGradient')
        .attr('id', 'gradient')
        .attr('x1', '0%')
        .attr('y1', '50%')
        .attr('x2', '100%')
        .attr('y2', '50%')
        .attr('spreadMethod', 'pad');

      gradient.append('svg:stop')
        .attr('offset', '0%')
        .attr('stop-color', 'var(--fuse-primary-100)')
        .attr('stop-opacity', 1);

      gradient.append('svg:stop')
        .attr('offset', '100%')
        .attr('stop-color', 'var(--fuse-primary-100)')
        .attr('stop-opacity', 1);
    };

    const labels = function (selection) {
      selection.each(function (interval) {
        const years = calculateYearSeparatorsData(interval, layout);
        const g = d3.select(this).selectAll('.year-separator').data(years);

        g.exit().remove();

        const gEnter = g.enter().append('g').attr('class', 'year-separator');

        gEnter.append('rect');
        gEnter.append('text').attr('dy', '.33em');

        g.select('rect')
          .attr('x', function (year) {
            return year.x1;
          })
          .attr('y', 0)
          .attr('width', d3.functor(layout.width))
          .attr('class', function (year) {
            return 'year-separator-' + year.type;
          })
          .attr('height', d3.functor(layout.height))
          .style('fill', function () {
            return 'var(--fuse-primary-50)';
          })
          .style('stroke-width', function () {
            return '1';
          })
          .style('stroke', function () {
            return 'white';
          });

        g.select('text')
          .attr('x', function (year) {
            const multiplier = year.type === 'start' ? 1 : -1;
            const x = year.type === 'start' ? year.x1 : (year.x1 + layout.width);
            const padding = layout.paddingLeftRight * multiplier;

            return x + padding;
          })
          .attr('y', layout.paddingTop)
          .attr('text-anchor', function (year) {
            return year.type;
          })
          .text(function (year) {
            return year.renderLabel ? year.label : '';
          });
      });
    };

    const groups = function (selection) {
      if (!initialized) {
        initGradient(selection);
        initialized = true;
      }

      selection.each(function () {
        const g = d3.select(this).selectAll('.year-separators').data(intervals);

        g.exit().remove();
        g.enter().append('g').attr('class', 'year-separators');
        g.attr('transform', function (interval) {
          return 'translate(' + layout.x + ', ' + (layout.y + interval.y) + ')';
        });
        g.call(labels);
      });
    };

    groups.intervals = function (_) {
      if (!arguments.length) {
        return intervals;
      }
      intervals = _;

      return groups;
    };

    groups.layout = function (_) {
      if (!arguments.length) {
        return layout;
      }
      layout = _;

      return groups;
    };

    return groups;
  };
}
