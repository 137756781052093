import { Route } from '@angular/router';
import { DenyAnonymousGuard, ensureCurrentUser, requireRole, UserRole } from '@security/user';

export const CONTENT_ROUTES: Route[] = [
  {
    path: 'welcome',
    canActivate: [DenyAnonymousGuard, ensureCurrentUser(), requireRole(UserRole.PM)],
    loadComponent: () => import('./welcome/components/welcome/welcome.component').then(c => c.WelcomeComponent)
  },
  {
    path: 'terms',
    loadComponent: () => import('./terms/components/terms/terms.component').then(c => c.TermsComponent)
  },
  {
    path: 'imprint',
    loadComponent: () => import('./imprint/components/imprint/imprint.component').then(c => c.ImprintComponent)
  },
  {
    path: 'contact',
    loadComponent: () => import('./contact/components/contact/contact.component').then(c => c.ContactComponent)
  },
  {
    path: 'cookie-policy',
    loadComponent: () => import('./cookies/components/cookie-policy-page/cookie-policy-page.component').then(c => c.CookiePolicyPageComponent)
  }
];
