import { Injectable } from '@angular/core';
import { DateConverterService } from '@shared/consumption';
import { RoomsConsumption, RoomsConsumptionFromApi } from '../models';

@Injectable({
  providedIn: 'root'
})
export class RoomConsumptionsConversionService {

  public constructor(
    private dateConverter: DateConverterService
  ) { }

  public convertDateStringToDateObject(data: RoomsConsumptionFromApi): RoomsConsumption {
    const result: RoomsConsumption = {
      ...data,
      roomsWithValues: []
    };

    data.roomsWithValues.forEach(room => {
      if (!room.realRoom) {
        room.label = 'ROOMS_CONSUMPTION_UNASSIGNED';
      }

      const values = Object.keys(room.values).map(key => ({
        date: this.dateConverter.convertDateStringToDateObject(key),
        value: room.values[key]
      }));

      result.roomsWithValues.push({
        ...room,
        values
      });
    });

    return result;
  }
}
