import { AfterViewInit, ChangeDetectionStrategy, Component, Input, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { CookieService } from 'ngx-cookie';
import {
  NoticeENComponent, NoticeDEComponent, NoticeHRComponent, NoticeITComponent,
  NoticeROComponent, NoticePLComponent
} from './notices';
import { TranslateModule } from '@ngx-translate/core';
import { MatButton } from '@angular/material/button';
import { RouterLink } from '@angular/router';
import { NgIf } from '@angular/common';
import { NgIncludeDirective } from '@shared/layout/directives/ng-include';

export const COOKIE_NAME = 'ic.cookie-accepted';

@Component({
    selector: 'ic-accept-cookies',
    templateUrl: './accept-cookies.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, NgIncludeDirective, RouterLink, MatButton, TranslateModule]
})
export class AcceptCookiesComponent implements AfterViewInit {
  @ViewChild(NgIncludeDirective, { static: false })
  public noticePlaceholder: NgIncludeDirective;

  public accepted: boolean;

  public constructor(
    private cookie: CookieService
  ) {
    this.accepted = !!this.cookie.get(COOKIE_NAME);
  }

  @Input()
  public set language(language: string) {
    this.includeNoticeByLanguageCode(language);
  }

  public ngAfterViewInit() {
    this.includeNoticeByLanguageCode(this.language || 'en-GB');
  }

  public acceptCookiePolicy() {
    const expires = moment().add(12, 'months').toDate();
    this.cookie.put(COOKIE_NAME, 'true', { expires });

    this.accepted = true;
  }

  private includeNoticeByLanguageCode(language: string) {
    if (!this.noticePlaceholder) { return; }

    const viewContainer = this.noticePlaceholder.viewContainer;
    viewContainer.clear();

    const componentType = this.findTypeByLanguage(language);

    if (!componentType) {
      throw new Error(`No cookie notice found for selected language code: ${language}`);
    }

    viewContainer.createComponent(componentType);
  }

  private findTypeByLanguage(language: string) {
    switch (language) {
      case 'de': {
        return NoticeDEComponent;
      }
      case 'hr': {
        return NoticeHRComponent;
      }
      case 'it': {
        return NoticeITComponent;
      }
      case 'pl': {
        return NoticePLComponent;
      }
      case 'ro': {
        return NoticeROComponent;
      }
      case 'en':
      default: {
        return NoticeENComponent;
      }
    }
  }
}
