<form #form="ngForm" [formGroup]="formGroup" (ngSubmit)="onSubmit()" novalidate autocomplete="off" class="md:w-72">
  <div class="flex flex-col">
    <mat-form-field [floatLabel]="'always'" class="flex-auto gt-xs:pr-3">
      <mat-label>{{ "CURRENT_PASSWORD_LABEL" | translate }}</mat-label>

      <input matInput [formControlName]="FormKeys.CURRENT_PASSWORD" class="form-control" type="password" />

      @if (controlHasError(FormKeys.CURRENT_PASSWORD)) {
        <mat-error>
          {{ "ERROR_PASSWORD_REQUIRED" | translate }}
        </mat-error>
      }
    </mat-form-field>
  </div>

  <ic-new-password-fields-section class="flex flex-col gap-2 mt-2" [controlHasErrorFn]="controlHasError.bind(this)">
  </ic-new-password-fields-section>

  <div class="mt-4">
    <button
      type="submit"
      mat-raised-button
      color="primary"
      [disabled]="formGroup.invalid"
      class="w-full md:w-auto md:min-w-48"
    >
      <mat-icon>check</mat-icon>
      {{ "CHANGE_PASSWORD" | translate }}
    </button>
  </div>
</form>
