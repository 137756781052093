import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NgxSpinnerModule } from 'ngx-spinner';

@Component({
    selector: 'ic-spinner',
    template: `
    <ngx-spinner
      bdColor="rgba(0,0,0,0.55)"
      color="#fff"
      type="ball-clip-rotate"
      size="medium"
      [fullScreen]="true">
    </ngx-spinner>
  `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgxSpinnerModule]
})
export class SpinnerComponent {
}
