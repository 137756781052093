<!-- NOTE: markup implementation taken from fl-ui-phoenix-shell -->
<div class="flex items-center px-8 py-4 bg-primary-50/35">
  <div class="uppercase text-[15px]" *ngFor="let conf of configuration; let isLast = last">
    <span *ngIf="configuration.length === 1; then singleBlock; else multipleBlock"></span>
    <ng-template #singleBlock>
      <span class="text-primary-500">{{ conf.label | translate }}</span>
    </ng-template>
    <ng-template #multipleBlock>
      <span *ngIf="!isLast" class="text-gray-500 hover:text-gray-600 cursor-pointer" (click)="handleNavigation(conf)">{{
        conf.label | translate
      }}</span>
      <span *ngIf="!isLast" class="mx-[3px] text-gray-500">/</span>
      <span *ngIf="isLast" class="text-primary-500"> {{ conf.label | translate }}</span>
    </ng-template>
  </div>
</div>
