/* eslint-disable */
// NOTE: there's a lot of legacy code here wrapped inside the Angular service.
// Pls have in mind that even a minor refactoring can break it. Ensure you know what you're doing.
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PieChartSumServiceFactory {

  get() {
    return (new PieChartSumService()).sum();
  }
}

function PieChartSumService() {
  this.sum = function () {
    let layout, unit, total;

    const sum = function (selection) {

      const sumGroup = selection
        .selectAll('g.sum')
        .data([
          { total: total, unit: unit }
        ]);

      sumGroup.enter()
        .append('g')
        .attr('class', 'sum')
        .call(function (item) {
          if (layout.moneyChart) {
            singleLineTotal();
          } else {
            doubleLineTotal();
          }

          function singleLineTotal() {
            item.append('text')
              .attr('class', 'sum-money')
              .attr('font-size', layout.fontSize)
              .attr('text-anchor', 'middle')
              .attr('x', layout.centerX)
              .attr('y', layout.centerY + (layout.fontSize / 3));
          }

          function doubleLineTotal() {
            item.append('text')
              .attr('class', 'sum-qty')
              .attr('font-size', layout.fontSize)
              .attr('text-anchor', 'middle')
              .attr('x', layout.centerX)
              .attr('y', layout.centerY - Math.floor(layout.fontSize / 4));

            item.append('text')
              .attr('class', 'sum-unit')
              .attr('font-size', layout.fontSize)
              .attr('text-anchor', 'middle')
              .attr('x', layout.centerX)
              .attr('y', layout.centerY + layout.fontSize);
          }
        });

      if (layout.moneyChart) {
        sumGroup.select('.sum-money').text(function (d) {
          return d.total;
        });
      } else {
        sumGroup.select('.sum-qty').text(function (d) {
          return d.total;
        });
        sumGroup.select('.sum-unit').text(function (d) {
          return d.unit;
        });
      }

      sumGroup.exit().remove();

    };

    sum.unit = function (_) {
      if (!arguments.length) {
        return unit;
      }
      unit = _;

      return sum;
    };

    sum.total = function (_) {
      if (!arguments.length) {
        return total;
      }
      total = _;

      return sum;
    };

    sum.layout = function (_) {
      if (!arguments.length) {
        return layout;
      }
      layout = _;

      return sum;
    };

    return sum;
  };
}
