import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie';

@Injectable({ providedIn: 'root' })
export class CookieStorageService {
  public static KEYS = {
    BEARER: 'bearer',
    DOWNLOAD: 'download'
  };

  public constructor(
    private cookie: CookieService
  ) { }

  public setToken(key: string, value: any) {
    this.cookie.put(key, value, { path: '/', sameSite: 'strict', secure: false }); // TODO: use env configuration
  }

  public getToken(key: string) {
    return this.cookie.get(key);
  }

  public removeToken(key: string) {
    this.cookie.remove(key, { path: '/' });
  }
}
