import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ErrorCodeService {

  public createErrorMessage(response: HttpErrorResponse, mainCategory: string, defaultCategory: string, defaultMessagesOnly: boolean) {
    return defaultMessagesOnly
      ? this.getDefaultErrorMessage(mainCategory, defaultCategory)
      : this.getUserDefinedErrorMessage(response, mainCategory, defaultCategory);
  }

  private getDefaultErrorMessage(mainCategory: string, defaultCategory: string) {
    return `${mainCategory}.${defaultCategory}`;
  }

  private getUserDefinedErrorMessage(response: HttpErrorResponse, mainCategory: string, defaultCategory: string) {
    const { hasTokenError, hasEmailInvalidError, hasEmailNotUniqueError } = this.identifyErrors(response);
    let message = this.getDefaultErrorMessage(mainCategory, defaultCategory);

    if (response.status === 400 && hasTokenError) {
      const tokenErrorCode = response.error.errors.token[0].code;

      message = `${mainCategory}.ERROR_${tokenErrorCode}`;
    }

    if (response.status === 400 && hasEmailInvalidError) {
      const emailInvalidErrorCode = response.error.errors.newEmailAddress[0].code;

      message = `${mainCategory}.ERROR_${emailInvalidErrorCode}`;
    }

    if (response.status === 400 && hasEmailNotUniqueError) {
      const emailNotUniqueErrorCode = response.error.errors.userRegistrationWithTokenModel[0].code;

      message = `${mainCategory}.ERROR_${emailNotUniqueErrorCode}`;
    }

    return message;
  }

  private identifyErrors(response: HttpErrorResponse) {
    // 'INVALID_REGISTRATION_TOKEN' , 'EXPIRED_REGISTRATION_TOKEN'
    const hasTokenError = !!response.error?.errors?.token?.length;
    // INVALID_EMAIL
    const hasEmailInvalidError = !!response.error?.errors?.newEmailAddress?.length;
    // EMAIL_NOT_UNIQUE
    const hasEmailNotUniqueError = !!response.error?.errors?.userRegistrationWithTokenModel?.length;

    return { hasTokenError, hasEmailInvalidError, hasEmailNotUniqueError };
  }
}
