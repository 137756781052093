import { Injectable } from '@angular/core';
import { BehaviorSubject, first, Observable, shareReplay, switchMap, tap } from 'rxjs';
import { SettingsResource } from './settings.resource';
import { Settings, UpdateSettingsPayload } from './settings';
import { CurrentUserService } from '@security/user';

@Injectable({ providedIn: 'root' })
export class SettingsService {

  public settings$: Observable<Settings>;

  private settingsBus = new BehaviorSubject<void>(undefined);

  public constructor(
    private resource: SettingsResource,
    private currentUser: CurrentUserService
  ) {
    this.settings$ = this.selectSettings();
  }

  public update(payload: UpdateSettingsPayload) {
    return this.currentUser.ensureUser$.pipe(
      first(),
      switchMap(({ id }) => this.resource.update(id, payload)),
      tap(() => this.refresh())
    );
  }

  public refresh() {
    this.settingsBus.next();
  }

  private selectSettings() {
    return this.settingsBus.pipe(
      switchMap(() => this.currentUser.ensureUser$.pipe(first())),
      switchMap(({ id }) => this.resource.load(id)),
      shareReplay(1)
    );
  }
}
