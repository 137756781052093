import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environment';

@Injectable({
  providedIn: 'root'
})
export class ConsumptionPeriodsApiService {

  public constructor(
    private http: HttpClient
  ) { }

  public getAllPeriodsData({ userUuid }: { userUuid: string }) {
    return this.http.get(
      `${environment.restEndpoint}/user/${userUuid}/consumption/period`
    );
  }
}
