import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, filter, Observable, shareReplay, switchMap, throwError } from 'rxjs';
import { NotificationService, Notification } from '@shared/notifications';
import { MeteringPointDetails } from '../models';
import { MeteringPointResource } from './metering-point-api.service';

@Injectable({
  providedIn: 'root'
})
export class MeteringPointService {
  public unmapped$: Observable<MeteringPointDetails[]>;

  public unmappedBus = new BehaviorSubject<string>(undefined);

  public constructor(
    private resource: MeteringPointResource,
    private notifications: NotificationService
  ) {
    this.subscribeToUnmapped();
  }

  public refresh(userUuid) {
    this.unmappedBus.next(userUuid);
  }

  private subscribeToUnmapped() {
    this.unmapped$ = this.unmappedBus.pipe(
      filter(userUuid => !!userUuid),
      switchMap(userUuid => this.resource.unmapped({ userUuid })),
      shareReplay(1),
      catchError((ex) => {
        this.handleLoadUnmappedMeteringPointsFailure();
        return throwError(ex);
      })
    );
  }

  private handleLoadUnmappedMeteringPointsFailure() {
    this.notifications.show(
      Notification.toast().asWarning({
        messageKey: 'ROOMS_LOAD_ERROR'
      })
    );
  }
}
