export enum RoomType {
  LIVING_ROOM = 'LIVING_ROOM',
  TV_ROOM = 'TV_ROOM',
  DINING_ROOM = 'DINING_ROOM',
  BEDROOM = 'BEDROOM',
  KITCHEN = 'KITCHEN',
  KIDS_ROOM = 'KIDS_ROOM',
  HOBBY_ROOM = 'HOBBY_ROOM',
  OFFICE = 'OFFICE',
  BATHROOM = 'BATHROOM',
  HALL = 'HALL',
  TOILET = 'TOILET',
  MISC = 'MISC'
}
