import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Notification, NotificationService } from '@shared/notifications';
import { catchError, tap, throwError } from 'rxjs';
import { PasswordApiService } from './password-api.service';
import { ChangePasswordPayload } from '../models/change-password-payload.model';
import { ChangePasswordFailureReason } from '../models/change-password-failure-reason.model';
import { ChangePasswordErrorDetails } from '../models/change-password-error-details.model';


@Injectable({
  providedIn: 'root'
})
export class PasswordService {
  public constructor(
    private resource: PasswordApiService,
    private notifications: NotificationService
  ) {
  }

  public changePassword(userId: number, payload: ChangePasswordPayload) {
    return this.resource
      .changePassword(userId, payload)
      .pipe(
        tap(() => this.onPasswordChanged()),
        catchError(response => throwError(() => this.onPasswordChangeFail(response)))
      );
  }

  private onPasswordChanged() {
    this.notifications.show(
      Notification.toast().asSuccess({ messageKey: 'CHANGE_PASSWORD_SUCCESS' })
    );
  }

  private onPasswordChangeFail(response: HttpErrorResponse) {
    if (response.status !== 400) { return ChangePasswordFailureReason.OTHER; }

    return this.parseErrorCode(
      this.getErrorCode(response.error)
    );
  }

  private parseErrorCode(errorCode: string) {
    switch (errorCode) {
      case 'invalidPasswordHistoryMessage': {
        return ChangePasswordFailureReason.USED_RECENTLY;
      }
      case 'WRONG_PASSWORD': {
        return ChangePasswordFailureReason.CURRENT_PASSWORD_IS_WRONG;
      }
      default: {
        return ChangePasswordFailureReason.OTHER;
      }
    }
  }

  private getErrorCode(details: ChangePasswordErrorDetails) {
    const hasErrorsList = !!details.errors
      && Object.values(details.errors).length;

    if (!hasErrorsList) { return details.error; }

    const firstControlErrors = Object.values(details.errors)[0];
    return firstControlErrors[0].code;
  }
}
