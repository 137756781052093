import { Injectable } from '@angular/core';
import { CookieStorageService } from '@shared/storage';

@Injectable({ providedIn: 'root' })
export class SessionService {
  public constructor(
    private storage: CookieStorageService
  ) { }

  public getToken() {
    return this.storage.getToken(CookieStorageService.KEYS.BEARER);
  }

  public create(token: string) {
    if (!token) { this.destroy(); }
    else { this.setToken(token); }
  }

  public destroy() {
    this.removeToken();
  }

  private removeToken() {
    this.storage.removeToken(CookieStorageService.KEYS.DOWNLOAD);
    this.storage.removeToken(CookieStorageService.KEYS.BEARER);
  }

  private setToken(token: string) {
    this.storage.setToken(CookieStorageService.KEYS.BEARER, token);
  }
}
