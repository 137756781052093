import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: 'img[fallbackSrc]',
    standalone: true
})
export class FallbackImageSourceDirective {
  @Input()
  public fallbackSrc: string;

  public constructor(
    private element: ElementRef
  ) { }

  @HostListener('error')
  public loadFallbackOnError() {
    const element: HTMLImageElement = this.element.nativeElement;
    element.src = this.fallbackSrc;
  }
}
