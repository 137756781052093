/* eslint-disable */
// NOTE: there's a lot of legacy code here wrapped inside the Angular service.
// Pls have in mind that even a minor refactoring can break it. Ensure you know what you're doing.
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ChartNodeLabelsServiceFactory {
  get() {
    return (new LegacyChartNodeLabelsService()).nodeLabels();
  }
}

function LegacyChartNodeLabelsService() {
  this.nodeLabels = function () {
    let intervals, layout, formatter;
    let selectionCache;

    const labels = function (selection) {
      selection.each(function (interval) {
        const g = d3
          .select(this)
          .selectAll('.node-label')
          .data(interval.dataPoints);

        g.exit().remove();
        const gEnter = g.enter().append('g').attr('class', 'node-label');

        gEnter.append('rect');
        gEnter.append('text').attr('dy', '.33em');
        gEnter.append('path');

        g.select('rect')
          .attr('x', function (dataPoint) {
            return interval.xScale(dataPoint.date) - layout.box.width / 2;
          })
          .attr('y', function (dataPoint) {
            return (
              interval.yScale(dataPoint.value) -
              layout.box.height -
              layout.box.marginVertical
            );
          })
          .attr('width', d3.functor(layout.box.width))
          .attr('height', d3.functor(layout.box.height));

        g.select('text')
          .text(function (dataPoint) {
            return formatter(dataPoint.value);
          })
          .attr('x', function (dataPoint) {
            return interval.xScale(dataPoint.date);
          })
          .attr('y', function (dataPoint) {
            return (
              interval.yScale(dataPoint.value) -
              layout.box.height / 2 -
              layout.box.marginVertical
            );
          });

        g.select('path').attr('d', function (dataPoint) {
          const xStart = interval.xScale(dataPoint.date) - layout.arrow.width / 2;
          const yStart =
            interval.yScale(dataPoint.value) - layout.box.marginVertical - 1;

          return (
            'M' +
            xStart +
            ',' +
            yStart +
            'L' +
            (xStart + layout.arrow.width / 2) +
            ',' +
            (yStart + layout.box.marginVertical) +
            'L' +
            (xStart + layout.arrow.width) +
            ',' +
            yStart +
            ' Z'
          );
        });
      });

      selectionCache = selection.selectAll('.node-label');
    };

    const groups = function (selection) {
      selection.each(function () {
        const g = d3.select(this).selectAll('.node-labels').data(intervals);

        g.exit().remove();
        g.enter().append('g').attr('class', 'node-labels');
        g.attr('transform', function (interval) {
          return 'translate(' + layout.x + ', ' + (layout.y + interval.y) + ')';
        });
        g.call(labels);
      });
    };

    groups.updateActive = function (selection, currentDate) {
      selectionCache.classed('active', function (dataPoint) {
        return dataPoint.date.getMonth() === currentDate.getMonth();
      });
    };

    groups.intervals = function (_) {
      if (!arguments.length) {
        return intervals;
      }
      intervals = _;

      return groups;
    };

    groups.layout = function (_) {
      if (!arguments.length) {
        return layout;
      }
      layout = _;

      return groups;
    };

    groups.formatter = function (_) {
      if (!arguments.length) {
        return formatter;
      }
      formatter = _;

      return groups;
    };

    return groups;
  };
}
