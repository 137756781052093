<fuse-drawer
  [name]="'leftDrawer'"
  [opened]="expanded()"
  [mode]="(isSmallScreen$ | async) ? 'over' : 'side'"
  [transparentOverlay]="false"
  class="ista-vertical-navigation"
  (openedChanged)="onDrawerToggled($event)"
>
  <div class="ista-sidenav">
    <a [routerLink]="['/welcome']">
      <img
        [src]="brandLogo + '?rev=' + assetsRev"
        [fallbackSrc]="'/assets/img/ista_Logo_horizontal_Connect_OceanBlue_RGB.svg?rev=' + assetsRev"
        alt="istaConnect"
        class="max-h-[80px]"
      />
    </a>

    <div class="px-4 pt-1 pb-4">
      <ic-main-navigation></ic-main-navigation>
    </div>
  </div>
</fuse-drawer>

<section>
  <mat-toolbar class="ista-ui-header md-whiteframe-z1">
    <button
      mat-icon-button
      class="ml-1 mdc-icon-button mat-mdc-icon-button mat-unthemed mat-mdc-button-base"
      (click)="toggleDrawer()"
    >
      <mat-icon class="icon-size-6">menu</mat-icon>
    </button>
    @if (!expanded()) {
      <a [routerLink]="['/welcome']" [@enterAnimation]>
        <img
          [src]="brandLogo + '?rev=' + assetsRev"
          [fallbackSrc]="'/assets/img/ista_Logo_horizontal_Connect_OceanBlue_RGB.svg?rev=' + assetsRev"
          alt="istaConnect"
          class="max-h-[80px]"
        />
      </a>
    }
    <div class="w-full">
      <ic-top-navigation class="p-4 flex flex-row justify-end"></ic-top-navigation>
    </div>
  </mat-toolbar>

  @if (breadcrumb) {
    <ic-breadcrumb [configuration]="breadcrumb"> </ic-breadcrumb>
  }

  <main>
    <ic-inline-notification></ic-inline-notification>

    <ng-content select="h1.page-header"></ng-content>
    <ng-content></ng-content>
  </main>

  <footer>
    <ic-app-footer [isBranded]="isBranded"></ic-app-footer>
  </footer>

  <div class="flex-cookies flex-sticky-cookies">
    <ic-accept-cookies class="accept-cookies" language="{{ (locale$ | async)?.lang }}"> </ic-accept-cookies>
  </div>
</section>

<ic-spinner></ic-spinner>
<ic-toasts></ic-toasts>
