import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environment';
import { AddOrDeleteMeteringPointPayload, MeteringPointDetails } from '../models';

@Injectable({ providedIn: 'root' })
export class MeteringPointResource {
  public constructor(
    private http: HttpClient
  ) { }

  public unmapped({ userUuid }: { userUuid: string }) {
    return this.http.get<MeteringPointDetails[]>(
      `${environment.restEndpoint}/user/${userUuid}/unmappedMeteringPoint`
    );
  }

  public add({ userUuid, roomUuid, meteringPointUuid }: AddOrDeleteMeteringPointPayload) {
    return this.http.post(
      `${environment.restEndpoint}/user/${userUuid}/room/${roomUuid}/meteringPoint/${meteringPointUuid}`,
      {}
    );
  }

  public delete({ userUuid, roomUuid, meteringPointUuid }: AddOrDeleteMeteringPointPayload) {
    return this.http.delete(
      `${environment.restEndpoint}/user/${userUuid}/room/${roomUuid}/meteringPoint/${meteringPointUuid}`
    );
  }

}
