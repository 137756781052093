/* eslint-disable */
// NOTE: there's a lot of legacy code here wrapped inside the Angular service.
// Pls have in mind that even a minor refactoring can break it. Ensure you know what you're doing.
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ChartBarServiceFactory {
  get() {
    return (new LegacyChartBarService()).bar();
  }
}

function LegacyChartBarService() {
  this.bar = function () {
    let layout;
    let groupElement, rectElement;

    const bar = function (selection) {
      if (!groupElement) {
        groupElement = selection.append('g').attr('class', 'bar');
        groupElement.attr('transform', 'translate(-100, 0)');
        rectElement = groupElement.append('rect');
      }

      rectElement.attr({
        x: 0,
        y: 0,
        width: layout.width,
        height: layout.height,
      });
    };

    bar.updateXPosition = function (selection, xPosition) {
      if (!groupElement) {
        return;
      }
      groupElement.attr(
        'transform',
        'translate(' + (xPosition - layout.width / 2) + ', 0)'
      );
    };

    bar.layout = function (_) {
      if (!arguments.length) {
        return layout;
      }
      layout = _;

      return bar;
    };

    return bar;
  };
}
