let correction; let roundedSum; let adjustedInput; let orderedList; let off; let correctedValue;

const roundWithPrecision = (num, precision) => {
  const rounded = parseFloat(num.toFixed(precision));
  return rounded;
};

export const hareNiemeyer = (input: any[], attribute, precision: number, target) => {
  roundedSum = input.reduce((acc, item) => {
    const rounded = roundWithPrecision(item[attribute], precision);
    return acc + rounded;
  }, 0);

  off = target - roundedSum;

  orderedList = input
    .map((item, i) => ({ id: i, payload: item[attribute] }))
    .sort((item) => roundWithPrecision(item.payload, precision) - item.payload)
    .map((item, i) => {
      correction = (+(off > i) - +(i >= (input.length + off))) / Math.pow(10, precision);
      correctedValue = roundWithPrecision(item.payload, precision) + correction;
      item.payload = roundWithPrecision(correctedValue, precision);

      return item;
    });

  adjustedInput = input.map((item: any, i) => {
    item[attribute] = orderedList.find((listItem: any) => listItem.id === i).payload;

    return item;
  });

  return adjustedInput;
};
