import {
  ChangeDetectionStrategy,
  Component
} from '@angular/core';
import { map, Observable, switchMap } from 'rxjs';
import { LanguageSelectionService } from '@shared/localization';
import { CountryFeatureService, CurrentUserService } from '@security/user';
import { TranslateModule } from '@ngx-translate/core';
import { RouterLink } from '@angular/router';
import { MatIcon } from '@angular/material/icon';
import { MatMenuTrigger, MatMenu, MatMenuItem } from '@angular/material/menu';
import { MatIconButton } from '@angular/material/button';
import { NgIf, NgFor, NgClass, AsyncPipe } from '@angular/common';

@Component({
    selector: 'ic-top-navigation',
    templateUrl: './top-navigation.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, MatIconButton, MatMenuTrigger, MatMenu, NgFor, MatMenuItem, NgClass, MatIcon, RouterLink, AsyncPipe, TranslateModule]
})
export class TopNavigationComponent {
  public allowedLocaleStrings = this.languageSelection.allowedLocaleStrings;

  public locale$ = this.languageSelection.locale$;
  public isChangePasswordEnabled$: Observable<boolean>;
  public isChangeEmailEnabled$: Observable<boolean>;
  public isAuthorized$: Observable<boolean>;

  public constructor(
    private languageSelection: LanguageSelectionService,
    private countryFeature: CountryFeatureService,
    protected currentUser: CurrentUserService
  ) {
    this.subscribeToCurrentUserFeatures();
  }

  public languageFromLocale(locale: string) {
    return locale.split('-')[0];
  }

  public async onChangeLanguage(locale: string) {
    await this.languageSelection.setLocale(locale);
  }

  private subscribeToCurrentUserFeatures() {
    this.isAuthorized$ = this.currentUser.ensureUser$.pipe(
      map(user => !!user)
    );
    this.isChangePasswordEnabled$ = this.currentUser.ensureUser$.pipe(
      switchMap(() => this.countryFeature.isChangePasswordEnabled())
    );
    this.isChangeEmailEnabled$ = this.currentUser.ensureUser$.pipe(
      switchMap(() => this.countryFeature.isChangeEmailEnabled())
    );
  }
}
