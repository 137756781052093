import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { filter, map } from 'rxjs';
import { DateFormats } from '@shared/common';
import { LanguageSelectionService } from '@shared/localization';

/**
 * A general usage date-formatting pipe for istaConnect.
 * It is preferred to use it instead of built-in DatePipe.
 * Uses moment.js library under the hood and therefore shares the same date format notation.
 *
 * @usageNotes
 *
 * Pipe accepts either Date or string value type. Requires at least one attribute containing the desired date format.
 *
 * ### First attr: desired format
 *
 * Sets the desired format in which the date will be displayed
 *
 * ### Second (optional) attr: parse format
 *
 * Sets the format for parsing the input value. If not set, DateFormats.dateReverse will be used by default.
 */
@Pipe({
    name: 'momentFormat$',
    standalone: true
})
export class MomentFormatPipe implements PipeTransform {
  public constructor(
    private languageSelection: LanguageSelectionService
  ) { }

  public transform(value: Date | string, ...args: string[]) {
    if (!args.length) { throw new Error('MomentFormatPipe: no arguments passed'); }

    const format = args[0];
    const parseFormat = args[1] || DateFormats.dateReverse;

    return this.languageSelection.locale$.pipe(
      filter(locale => !!locale),
      map(() => {
        const date = moment(value, parseFormat);

        return !date.isValid()
          ? null
          : date.format(format);
      })
    );
  }

}
