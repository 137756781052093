import { Injectable } from '@angular/core';
import { DisplayedElements } from '../models';

@Injectable({
  providedIn: 'root'
})
export class RoomConsumptionsViewService {

  public getDefaultViewMode(): DisplayedElements {
    return {
      consumptionNormalizedTabs: false,
      renderedPie: false,
      renderedEmptyChart: false,
      timerangeSelector: false,
      consumptionTable: false,
      editRoomsButton: false,
      pieChart: false
    };
  }

  public getInvalidRangeViewMode(displayedElements: DisplayedElements): DisplayedElements {
    displayedElements.consumptionNormalizedTabs = true;
    displayedElements.timerangeSelector = true;
    displayedElements.renderedPie = true;
    displayedElements.renderedEmptyChart = true;
    displayedElements.consumptionTable = false;
    displayedElements.editRoomsButton = false;
    displayedElements.pieChart = false;

    return displayedElements;
  }

  public getValidRangeViewMode(displayedElements: DisplayedElements): DisplayedElements {
    displayedElements.consumptionNormalizedTabs = true;
    displayedElements.timerangeSelector = true;
    displayedElements.renderedPie = true;
    displayedElements.renderedEmptyChart = false;
    displayedElements.consumptionTable = true;
    displayedElements.editRoomsButton = true;
    displayedElements.pieChart = true;

    return displayedElements;
  }

  public getLoadErrorViewMode(displayedElements: DisplayedElements): DisplayedElements {
    displayedElements.consumptionNormalizedTabs = false;
    displayedElements.timerangeSelector = false;
    displayedElements.renderedPie = true;
    displayedElements.renderedEmptyChart = false;
    displayedElements.consumptionTable = false;
    displayedElements.editRoomsButton = false;
    displayedElements.pieChart = false;

    return displayedElements;
  }

  public getNoRoomsAvailableViewMode(displayedElements: DisplayedElements): DisplayedElements {
    displayedElements.consumptionNormalizedTabs = false;
    displayedElements.timerangeSelector = false;
    displayedElements.renderedPie = false;
    displayedElements.renderedEmptyChart = true;
    displayedElements.consumptionTable = false;
    displayedElements.editRoomsButton = false;
    displayedElements.pieChart = true;

    return displayedElements;
  }

  public getMissingRoomSurfaceViewMode(displayedElements: DisplayedElements): DisplayedElements {
    displayedElements.consumptionNormalizedTabs = true;
    displayedElements.timerangeSelector = false;
    displayedElements.renderedPie = true;
    displayedElements.renderedEmptyChart = false;
    displayedElements.consumptionTable = false;
    displayedElements.editRoomsButton = true;
    displayedElements.pieChart = false;

    return displayedElements;
  }

  public getConsumptionDataAvailableViewMode(displayedElements: DisplayedElements): DisplayedElements {
    displayedElements.consumptionNormalizedTabs = true;
    displayedElements.timerangeSelector = true;
    displayedElements.renderedPie = true;
    displayedElements.renderedEmptyChart = false;
    displayedElements.consumptionTable = true;
    displayedElements.editRoomsButton = true;
    displayedElements.pieChart = true;

    return displayedElements;
  }
}
