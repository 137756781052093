import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { share } from 'rxjs';
import { environment } from '@environment';
import { ConsumptionType } from '@shared/consumption';
import { TimeUnit } from '@shared/common';
import { ConsumptionAverageDataPoints, ConsumptionDataPoints, ConsumptionUnit, DeviceDetails, LatestConsumption } from '../models';

export interface GetConsumptionPayload {
  type: ConsumptionType; userUuid: string; timeunit: TimeUnit; unit: ConsumptionUnit;
}

@Injectable({
  providedIn: 'root'
})
export class ConsumptionApiService {

  public constructor(
    private http: HttpClient
  ) { }

  public getLatest({ userUuid, timeunit }: { userUuid: string; timeunit: TimeUnit }) {
    return this.http
      .get<LatestConsumption>(
        `${environment.restEndpoint}/user/${userUuid}/consumption/${timeunit}/latest`
      )
      .pipe(
        share()
      );
  }

  public getDataPoints({ type, userUuid, timeunit, unit }: GetConsumptionPayload) {
    return this.http
      .get<ConsumptionDataPoints>(
        `${environment.restEndpoint}/user/${userUuid}/consumption/${type}/${unit}/${timeunit}`
      )
      .pipe(
        share()
      );
  }

  public getAverage({ type, userUuid, timeunit, unit }: GetConsumptionPayload) {
    return this.http
      .get<ConsumptionAverageDataPoints>(
        `${environment.restEndpoint}/user/${userUuid}/consumption/propertyAverage/${type}/${unit}/${timeunit}`
      )
      .pipe(
        share()
      );
  }

  public getDeviceDetails({ type, userUuid, timeunit, unit }: GetConsumptionPayload) {
    return this.http
      .get<DeviceDetails>(
        `${environment.restEndpoint}/user/${userUuid}/consumption/devices/${type}/${unit}/${timeunit}`
      )
      .pipe(
        share()
      );
  }
}
