import { ElementRef, Injectable } from '@angular/core';
import { formatNumber } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { PieChartLayoutServiceFactory } from './pie-chart-layout.service';
import { PieChartSlicesServiceFactory } from './pie-chart-slices.service';
import { PieChartSumServiceFactory } from './pie-chart-sum.service';
import { PieChartIconsServiceFactory } from './pie-chart-icons.service';
import { PieChartLabelServiceFactory } from './pie-chart-label.service';
import { PieChartPointerEventsServiceFactory } from './pie-chart-pointer-events.service';
import { PieChartRoomDataServiceFactory } from './pie-chart-room-data.service';
import { absoluteCompare, assign } from '@shared/common';
import { currencySymbol } from '../services';

@Injectable({
  providedIn: 'root'
})
export class PieChartDrawServiceFactory {

  public constructor(
    private layoutFactory: PieChartLayoutServiceFactory,
    private slicesFactory: PieChartSlicesServiceFactory,
    private sumFactory: PieChartSumServiceFactory,
    private iconsFactory: PieChartIconsServiceFactory,
    private labelsFactory: PieChartLabelServiceFactory,
    private pointerEventsFactory: PieChartPointerEventsServiceFactory,
    private roomDataFactory: PieChartRoomDataServiceFactory,
    private translation: TranslateService
  ) { }

  public get(): DrawService {
    return (
      new PieChartDrawService(
        this.translation,
        this.layoutFactory,
        this.slicesFactory.get(),
        this.sumFactory.get(),
        this.iconsFactory.get(),
        this.labelsFactory.get(),
        this.pointerEventsFactory,
        this.roomDataFactory.get()
      )
    );
  }
}

interface DrawService {
  createPie: (data, options) => PieChartInstance;
}

export interface PieChartInstance {
  update: (newData) => void;
  appendTo: (element) => void;
}

function PieChartDrawService(
  $translate: TranslateService,
  // eslint-disable-next-line @typescript-eslint/no-shadow
  PieChartLayoutServiceFactory,
  slices,
  sum,
  icons,
  labels,
  // eslint-disable-next-line @typescript-eslint/no-shadow
  PieChartPointerEventsServiceFactory,
  roomData
) {

  this.createPie = function(data, options) {
    let selection; let svgElement: SVGSVGElement; let layout; let eventHandler;

    const roomIsAloneWithConsumptions = (rooms) => {
      const alone = roomData.roomsAboveZeroPercentage(rooms).length === 1;

      return alone;
    };

    const translateUnit = (unit) => {
      const unitEmpty = () => !unit;

      const translation = () => {
        const translationKey = 'UNIT_' + data.unit + '_SHORT';
        const translatedString = $translate.instant(translationKey);

        return absoluteCompare(translatedString, translationKey) ?
          currencySymbol(null, data.unit, 0) :
          translatedString;
      };

      return unitEmpty() ? '' : translation();
    };

    const updateAllElements = () => {
      const adjustRadius = (total) => {
        const numberOfCharacters = total.toString().length;

        if (numberOfCharacters > 8) {
          const characterWidth = (layout.sum.fontSize / 4);

          layout.pie.innerRadius = characterWidth + numberOfCharacters * characterWidth;
        }
      };

      layout = PieChartLayoutServiceFactory.get(options);

      svgElement.setAttribute('viewBox', '0 0 ' + layout.width + ' ' + layout.height);
      if (typeof (data) !== undefined && Array.isArray(data.roomsWithValues)) {
        const rooms = data.roomsWithValues;
        const total = roomData.calculateTotal(rooms);

        const unitTranslation = (data.unit !== '') ? translateUnit(data.unit) : '';
        const totalPrecision = total > 999 ? 0 : 1;
        const locale = $translate.currentLang.substring(0, 2);

        const totalFormatted = options.moneyChart
          ? currencySymbol(total, data.unit)
          : formatNumber(total, locale, `1.${totalPrecision}-${totalPrecision}`);

        if (options.moneyChart) {
          adjustRadius(totalFormatted);
        }

        sum
          .layout(layout.sum)
          .unit(unitTranslation)
          .total(totalFormatted);

        slices
          .layout(layout.pie)
          .rooms(roomData.convertRoomsToSlicesData(rooms, options.percentagePrecision))
          .pullOutPieSegment(false)
          .pullOutPieSegmentAllowed(!roomIsAloneWithConsumptions(rooms));

        icons
          .layout(layout.icon)
          .rooms(roomData.convertRoomsToIconsData(rooms, options.percentagePrecision))
          .pullOutPieSegment(false)
          .pullOutPieSegmentAllowed(!roomIsAloneWithConsumptions(rooms));

        labels
          .rooms(roomData.convertRoomsToLabelsData(rooms, options.percentagePrecision))
          .layout(layout.labels)
          .pullOutPieSegment(false)
          .pullOutPieSegmentAllowed(!roomIsAloneWithConsumptions(rooms));

        window.requestAnimationFrame(() => {
          selection
            .call(sum)
            .call(slices)
            .call(icons)
            .call(labels);
        });
      }

      eventHandler.setLayout(layout);
    };

    this.update = (newData) => {
      if (newData) {
        data = assign(data, newData);
      }
      updateAllElements();
    };

    const setRoomActive = ({ detail }: CustomEvent<{ roomId; pullOutPieSegment }>) => {

      if (!roomIsAloneWithConsumptions(data.roomsWithValues)) {
        slices
          .activeRoomState(detail.roomId)
          .pullOutPieSegment(detail.pullOutPieSegment)
          .pullOutPieSegmentAllowed(true);

        labels
          .activeRoomState(detail.roomId)
          .pullOutPieSegment(detail.pullOutPieSegment)
          .pullOutPieSegmentAllowed(true);

        icons
          .activeRoomState(detail.roomId)
          .pullOutPieSegment(detail.pullOutPieSegment)
          .pullOutPieSegmentAllowed(true);

        selection
          .call(slices)
          .call(labels)
          .call(icons);
      }
    };

    const setupEvents = () => {
      svgElement.addEventListener('roomActiveInPie', setRoomActive);
      svgElement.addEventListener('roomActiveInTable', setRoomActive);
    };

    this.appendTo = (element: ElementRef<HTMLElement>) => {
      svgElement = element.nativeElement.querySelector('svg');
      selection = d3.select(svgElement);

      eventHandler = PieChartPointerEventsServiceFactory.get(svgElement, layout);

      selection.append('g').classed('pie-slice-group', true);
      selection.append('g').classed('pie-label-group', true);
      selection.append('g').classed('pie-icon-group', true);

      this.update(null);

      setupEvents();
      eventHandler.activate();
    };

    return this;
  };
}
