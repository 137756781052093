import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EmailApiService } from '@security/change-email';
import { CurrentUserService, SessionService } from '@security/user';
import { NotificationService, Notification, InlineNotificationComponent } from '@shared/notifications';
import { DefaultLayoutComponent } from '@shared/layout/components/default-layout';
import { BreadcrumbConfiguration } from '@shared/layout/models';

@Component({
    selector: 'ic-activate-new-email',
    template: `
		<ic-default-layout [breadcrumb]="breadcrumb">
      <ic-inline-notification [identifier]="INLINE_NOTIFICATION_ID">
      </ic-inline-notification>
		</ic-default-layout>
	`,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [DefaultLayoutComponent, InlineNotificationComponent]
})
export class ActivateNewEmailComponent implements OnInit {
  public readonly INLINE_NOTIFICATION_ID = 'activateNewEmail';
  public readonly breadcrumb: BreadcrumbConfiguration = [
    { label: 'EMAIL_CHANGE_ACTIVATION_TITLE' }
  ];

  public constructor(
    private route: ActivatedRoute,
    private emailResource: EmailApiService,
    private currentUser: CurrentUserService,
    private session: SessionService,
    private notification: NotificationService
  ) { }

  public get token() {
    return this.route.snapshot.params.token;
  }

  public ngOnInit(): void {
    this.emailResource
      .activateNewEmail(this.token)
      .subscribe({
        next: () => this.onEmailActivated(),
        error: () => this.onEmailActivationFailed()
      });
  }

  private async onEmailActivated() {
    this.notification.show(
      Notification.inline().asSuccess({
        messageKey: 'CHANGE_EMAIL_ACTIVATION_SUCCESS',
        placeholderId: this.INLINE_NOTIFICATION_ID,
        showLink: true,
        linkText: 'LOGIN',
        linkUrl: '/'
      })
    );

    if (this.session.getToken()) { this.currentUser.logout(); }
  }

  private onEmailActivationFailed() {
    this.notification.show(
      Notification.inline().asError({
        messageKey: 'CHANGE_EMAIL_ACTIVATION_ERROR',
        placeholderId: this.INLINE_NOTIFICATION_ID,
        showLink: true,
        linkText: 'CHANGE_EMAIL',
        linkUrl: '/email'
      })
    );
  }
}
