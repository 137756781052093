/* eslint-disable */
// NOTE: there's a lot of legacy code here wrapped inside the Angular service.
// Pls have in mind that even a minor refactoring can break it. Ensure you know what you're doing.
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ChartYAxisServiceFactory {
  get() {
    return (new LegacyChartYAxisService).yAxis();
  }
}

function LegacyChartYAxisService() {
  this.yAxis = function () {
    let intervals, layout, formatter;

    const yAxisLabels = function (selection) {
      selection.each(function (interval) {
        const d3YAxis = d3.svg
          .axis()
          .scale(interval.yScale)
          .tickValues(interval.yLabels)
          .tickFormat(function (tickValue) {
            return formatter(tickValue);
          })
          .outerTickSize(0)
          .innerTickSize(0)
          .orient('left');

        d3.select(this).call(d3YAxis).select('path.domain').remove();
      });
    };

    const yAxis = function (selection) {
      selection.each(function () {
        const g = d3.select(this).selectAll('.yaxis').data(intervals);

        g.exit().remove();
        g.enter().append('g').attr('class', 'yaxis');
        g.attr('transform', function (interval) {
          return 'translate(' + layout.x + ', ' + (layout.y + interval.y) + ')';
        });
        g.call(yAxisLabels);
      });
    };

    yAxis.intervals = function (_) {
      if (!arguments.length) {
        return intervals;
      }
      intervals = _;

      return yAxis;
    };

    yAxis.layout = function (_) {
      if (!arguments.length) {
        return layout;
      }
      layout = _;

      return yAxis;
    };

    yAxis.formatter = function (_) {
      if (!arguments.length) {
        return formatter;
      }
      formatter = _;

      return yAxis;
    };

    return yAxis;
  };
}
