import { Injectable } from '@angular/core';
import { IstaColor } from './color';

@Injectable({
  providedIn: 'root'
})
export class IstaColorPaletteService {
  public readonly colors: IstaColor[];
  public readonly colorPalleteArray: any[];

  public constructor() {
    this.colors = [
      { h: '220', s: '22.5', l: '52.9' },
      // {h: "14", s: "81.3", l: "79"},
      { h: '76', s: '54.7', l: '51.6' },
      { h: '43', s: '90.7', l: '58.0' },
      { h: '220', s: '22.3', l: '76.3' },
      { h: '14', s: '80.2', l: '52.4' },
      { h: '76', s: '54.5', l: '61.2' },
      { h: '43', s: '90.4', l: '63.1' },
      { h: '220', s: '54.4', l: '29.2' },
      { h: '14', s: '81.0', l: '62.9' },
      { h: '220', s: '81.8', l: '21.6' },
    ];

    this.colorPalleteArray = this.colors.map(color => this.convertToHsl(color));
  }

  public colorAsCss(index: number) {
    const color = this.colorPalleteArray[index % this.colorPalleteArray.length];

    return `hsl(${color.h}, ${color.s * 100}%, ${color.l * 100}%)`;
  }

  private convertToHsl(color: IstaColor) {
    return d3.hsl('hsl(' + color.h + ',' + color.s + '%,' + color.l + '%)');
  }
}
