/* eslint-disable */
// NOTE: there's a lot of legacy code here wrapped inside the Angular service.
// Pls have in mind that even a minor refactoring can break it. Ensure you know what you're doing.
import { Injectable } from '@angular/core';
import { assign } from '@shared/common';
import { PieChartPullOutServiceFactory } from './pie-chart-pull-out.service';
import { RoomTypesIconService } from './room-types-icon.service';

@Injectable({
  providedIn: 'root'
})
export class PieChartIconsServiceFactory {

  constructor(
    private pieChartPullOutServiceFactory: PieChartPullOutServiceFactory,
    private roomTypeIcons: RoomTypesIconService
  ) { }

  get() {
    return (
      new PieChartIconsService(this.pieChartPullOutServiceFactory.get(), this.roomTypeIcons)
    ).icons();
  }
}

function PieChartIconsService(PieChartPullOutService, roomTypeIcons: RoomTypesIconService) {
  this.icons = function () {
    let arc, layout, rooms;
    const pie = d3.layout.pie().sort(null);

    pie.value(function (d) {
      return d.sum;
    });

    let activeRoomState = {
      currentActiveRoom: null,
      previousActiveRoom: null
    };
    let pullOutPieSegment = false;
    let pullOutPieSegmentAllowed = false;

    const filterIcons = function (d) {
      const angleDiff = ( d.endAngle - d.startAngle) * (180 / Math.PI);

      if (angleDiff < layout.minAngle) {
        return false;
      }

      return d.data.roomIcon !== '';
    };

    const getTransformationParameter = function(d){
      const transformationParameter = PieChartPullOutService.getPullTransformationParameter(null, d, pullOutPieSegment, pullOutPieSegmentAllowed, activeRoomState, layout.pullOutDistance);

      activeRoomState = transformationParameter.activeRoomState;

      return transformationParameter.distance;
    };

    const getPullTransformationParameter = function (d) {
      arc = d3.svg.arc()
        .innerRadius(layout.innerRadius)
        .outerRadius(layout.outerRadius);
      const c = arc.centroid(d),
        x = c[0],
        y = c[1],
        h = Math.sqrt(x * x + y * y);

      const distance = getTransformationParameter(d);
      const iconRadius = layout.outerRadius - layout.distanceFromEdge + distance;
      const iconPositionX = ((x / h) * iconRadius) - layout.width / 2 + layout.centerX;
      const iconPositionY = ((y / h) * iconRadius) - layout.height / 2 + layout.centerY;

      return { x: iconPositionX, y: iconPositionY };
    };

    const icons = function (selection) {
      const sliceIcons = selection
        .select('g.pie-icon-group')
        .selectAll('text.room-icon')
        .data(pie(rooms).filter(filterIcons));

      sliceIcons.exit().remove();

      sliceIcons.enter()
        .append('text')
        .attr('font-size', '3.5rem')
        .attr('font-family', 'Material Symbols Outlined')
        .attr('fill', '#ffffff');
      sliceIcons.exit().remove();

      sliceIcons
        .attr('class', function (d) {
          return 'room-icon ibicon ibicon-6x ibicon-fw ibicon-' + d.data.roomIcon.replace(/_/g, '-').toLowerCase();
        })
        .text(function (d) {
          return roomTypeIcons.getIcon(d.data.roomIcon);
        })
        .attr('data-title', function (d) {
          return d.data.label;
        })
        .attr('transform', function (d) {
          if (d.data.values.length !== 0 && d.data.sum > 0) {
            const transformationParameter = getPullTransformationParameter(d);

            return 'translate(' + transformationParameter.x + ',' + transformationParameter.y + ')';
          }

          return null;
        });
    };

    icons.activeRoomState = function (_) {
      if (!arguments.length) {
        return activeRoomState;
      }
      activeRoomState.previousActiveRoom = assign({}, activeRoomState.currentActiveRoom);
      activeRoomState.currentActiveRoom = _;

      return icons;
    };

    icons.pullOutPieSegment = function (_) {
      if (!arguments.length) {
        return pullOutPieSegment;
      }
      pullOutPieSegment = _;

      return icons;
    };

    icons.pullOutPieSegmentAllowed = function (_) {
      if (!arguments.length) {
        return pullOutPieSegmentAllowed;
      }
      pullOutPieSegmentAllowed = _;

      return icons;
    };

    icons.rooms = function (_) {
      if (!arguments.length) {
        return rooms;
      }
      rooms = _;

      return icons;
    };

    icons.layout = function (_) {
      if (!arguments.length) {
        return layout;
      }
      layout = _;

      return icons;
    };

    return icons;
  };
}
