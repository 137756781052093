import { Inject, Injectable } from '@angular/core';
import { ColorService } from './color.service';
import { icBrand } from './brand.factory';

@Injectable({
  providedIn: 'root'
})
export class BrandingService {
  public constructor(
    private colorService: ColorService,
    @Inject(icBrand) private brandName: string
  ) {
  }

  public async initialize() {
    if (!this.brandName) {
      return;
    }

    await this.appendBrandStylesheet();
    this.overwriteCssVariables();
  }

  private appendBrandStylesheet() {
    return new Promise<void>((resolve, reject) => {
      const assetsRev = this.getUniqueHash();
      const styleTag = document.createElement('link');
      styleTag.rel = 'stylesheet';
      styleTag.type = 'text/css';
      styleTag.href = `/s2/brands/${this.brandName}/style.css?rev=${assetsRev}`;
      styleTag.onload = () => resolve();
      styleTag.onerror = () => reject();

      document.body.classList.add(`branding`);
      document.body.classList.add(`brand-${this.brandName}`);
      document.body.appendChild(styleTag);
    });
  }

  private getUniqueHash() {
    return +new Date();
  }

  private overwriteCssVariables() {
    this.setFullRangeOfColorVariables('leading', 'primary');
    this.setFullRangeOfColorVariables('accent', 'accent');
  }

  private setFullRangeOfColorVariables(srcName: string, destName: string) {
    for (let i = 100; i <= 900; i += 100) {
      this.setColorVariable(`--app-${srcName}-${(i / 10) + 10}`, `--fuse-${destName}-${i}`);
    }

    this.setColorVariable(`--app-${srcName}-5`, `--fuse-${destName}-50`);
    this.setColorVariable(`--app-${srcName}-100`, `--fuse-${destName}`);
  }

  private setColorVariable(srcName: string, destName: string) {
    const hexValue = getComputedStyle(document.querySelector(':root'))
      .getPropertyValue(srcName);

    document.body.style.setProperty(destName, hexValue);
    document.body.style.setProperty(`${destName}-rgb`, this.hexToRgb(hexValue));
  }

  private hexToRgb(hex: string): string {
    const rgb = this.colorService.hexToRgb(hex);
    return `${rgb[0]},${rgb[1]},${rgb[2]}`;
  }
}
