import {ChangeDetectionStrategy, Component, EventEmitter, input, Input, OnDestroy, OnInit, Output, ViewEncapsulation} from '@angular/core';
import { ControlContainer, FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel, MatError } from '@angular/material/form-field';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIcon } from '@angular/material/icon';
import { AttentionComponent } from '@shared/consumption';
import { FuseAlertComponent } from '@ista-ui/angular';

export enum FormKeys {
  NEW_PASSWORD = 'newPassword',
  NEW_PASSWORD_CONFIRMATION = 'newPasswordConfirmation'
}

export enum ValidationKeys {
  REQUIRED = 'required',
  STRONG_PASSWORD = 'strongpassword',
  PASSWORDS_DONT_MATCH = 'passwordsDontMatch'
}

@Component({
    selector: 'ic-new-password-fields-section',
    templateUrl: './new-password-fields-section.component.html',
    viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
    styleUrls: ['./new-password-fields-section.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    encapsulation: ViewEncapsulation.None,
    imports: [MatFormField,FuseAlertComponent, MatTooltip, MatIcon, MatLabel, MatInput, FormsModule, ReactiveFormsModule, MatError, TranslateModule,AttentionComponent]
})
export class NewPasswordFieldsSectionComponent implements OnInit, OnDestroy {

  public includeTooltip = input<boolean>(false);

  @Input()
  public controlHasErrorFn: (controlName: FormKeys, validatorName?: string) => boolean;

  @Output()
  public changed = new EventEmitter<void>();

  public FormKeys = FormKeys;
  public ValidationKeys = ValidationKeys;

  public constructor(
    public formGroup: FormGroupDirective
  ) {
  }

  public ngOnInit() {
    this.formGroup.form.addValidators([this.passwordsShouldMatch]);
  }

  public ngOnDestroy() {
    this.formGroup.form.removeValidators([this.passwordsShouldMatch]);
  }

  public formGroupHasError(validatorName?: string) {
    return !!this.formGroup.errors && (!validatorName || !!this.formGroup.errors[validatorName]);
  }

  public controlHasError(controlName: FormKeys, validatorName?: string) {
    if (!this.controlHasErrorFn) {
      return false;
    }

    return this.controlHasErrorFn(controlName, validatorName);
  }

  private passwordsShouldMatch(group: FormGroup) {
    const {newPassword, newPasswordConfirmation} = group.getRawValue();

    if (!newPassword || !newPasswordConfirmation) {
      return null;
    }

    return newPassword === newPasswordConfirmation
      ? null
      : {[ValidationKeys.PASSWORDS_DONT_MATCH]: true};
  }
}
