import { Component } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { AppErrorService } from '@errors/services/app-error.service';
import { distinctUntilChanged, filter } from 'rxjs';
import { LanguageSelectionService } from '@shared/localization';
import { BrandingService } from '@shared/branding';
import { AsyncPipe } from '@angular/common';
import { MatIconRegistry } from '@angular/material/icon';

@Component({
    selector: 'ic-root',
    template: `
      @if (locale$ | async) {
        <router-outlet></router-outlet>
      }
    `,
    standalone: true,
    imports: [RouterOutlet, AsyncPipe]
})
export class AppComponent {
  public locale$ = this.languageSelection.locale$;

  public constructor(
    private languageSelection: LanguageSelectionService,
    private router: Router,
    private branding: BrandingService,
    private appError: AppErrorService,
    private iconRegistry: MatIconRegistry
  ) {
    this.subscribeToAppError();
    this.initializeMaterial();
    this.branding.initialize();
  }

  private subscribeToAppError() {
    this.appError.error$
      .pipe(
        filter(error => !!error),
        distinctUntilChanged()
      )
      .subscribe({
        next: () => this.router.navigate(['/error'])
      });
  }

  private initializeMaterial() {
    this.iconRegistry.setDefaultFontSetClass('material-symbols-outlined');
  }
}
