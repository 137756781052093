import { Injectable } from '@angular/core';
import { PieChartPullOutServiceFactory } from './pie-chart-pull-out.service';
import { assign } from '@shared/common';

@Injectable({
  providedIn: 'root'
})
export class PieChartLabelServiceFactory {

  public constructor(
    private pieChartPullOutServiceFactory: PieChartPullOutServiceFactory
  ) { }

  public get() {
    return (
      new PieChartLabelService(this.pieChartPullOutServiceFactory.get()).labels()
    );
  }
}

function PieChartLabelService(PieChartPullOutService) {
  this.labels = () => {
    let arcs; let layout; let rooms; let arc;
    const pie = d3.layout.pie().sort(null);

    pie.value((d) => d.sum);

    let activeRoomState = {
      currentActiveRoom: null,
      previousActiveRoom: null
    };
    let pullOutPieSegment = false;
    let pullOutPieSegmentAllowed = false;

    const getTransformationParameter = (d) => {
      const transformationParameter = PieChartPullOutService.getPullTransformationParameter(
        null,
        d,
        pullOutPieSegment,
        pullOutPieSegmentAllowed,
        activeRoomState,
        layout.pullOutDistance
      );

      activeRoomState = transformationParameter.activeRoomState;

      return transformationParameter.distance;
    };

    const getPullTransformationParameter = (d) => {
      const middleAngle = (d.startAngle + (d.endAngle - d.startAngle) / 2) * (180 / Math.PI);
      // rotate text below the equator
      const textAngle = middleAngle < 90 || middleAngle > (360 - 90) ? middleAngle : middleAngle - 180;
      const c = arc.centroid(d);
      const x = c[0];
      const y = c[1];
      const h = Math.sqrt(x * x + y * y);

      const distance = getTransformationParameter(d);
      const labelRadius = layout.outerRadius + layout.labelDistance + distance;
      const textPositionX = ((x / h) * labelRadius) + layout.centerX;
      const textPositionY = ((y / h) * labelRadius) + layout.centerY;

      return { x: textPositionX, y: textPositionY, rotate: textAngle };
    };

    const labels = (selection) => {
      arc = d3.svg.arc()
        .innerRadius(layout.innerRadius)
        .outerRadius(layout.outerRadius);

      arcs = selection.select('g.pie-label-group').selectAll('text.pie-slice-label').data(pie(rooms));
      arcs.exit().remove();

      arcs
        .enter()
        .append('text')
        .attr('class', 'pie-slice-label')
        .attr('font-size', layout.fontSize)
        .attr('text-anchor', 'middle');

      arcs
        .attr('transform', (d) => {
          const transformationParameter = getPullTransformationParameter(d);

          // eslint-disable-next-line max-len
          return 'translate(' + transformationParameter.x + ',' + transformationParameter.y + ') rotate(' + transformationParameter.rotate + ')';
        })
        .attr('dy', '.35em')
        .text((d) => d.data.percentageLocalized + ' %');
    };

    labels.activeRoomState = (_) => {
      if (!arguments.length) {
        return activeRoomState;
      }
      activeRoomState.previousActiveRoom = assign({}, activeRoomState.currentActiveRoom);
      activeRoomState.currentActiveRoom = _;

      return labels;
    };

    labels.pullOutPieSegment = (_) => {
      if (!arguments.length) {
        return pullOutPieSegment;
      }
      pullOutPieSegment = _;

      return labels;
    };

    labels.pullOutPieSegmentAllowed = (_) => {
      if (!arguments.length) {
        return pullOutPieSegmentAllowed;
      }
      pullOutPieSegmentAllowed = _;

      return labels;
    };

    labels.rooms = (_) => {
      if (!arguments.length) {
        return rooms;
      }
      rooms = _;

      return labels;
    };

    labels.layout = (_) => {
      if (!arguments.length) {
        return layout;
      }
      layout = _;

      return labels;
    };

    return labels;
  };
}
