import { Injectable } from '@angular/core';
import { RoomType } from '@user/consumption';

@Injectable({ providedIn: 'root' })
export class RoomTypesIconService {
  public getIcon(room: RoomType) {
    switch (room) {
      case RoomType.LIVING_ROOM: {
        return 'weekend';
      }
      case RoomType.DINING_ROOM: {
        return 'restaurant';
      }
      case RoomType.HOBBY_ROOM: {
        return 'sports_esports';
      }
      case RoomType.KIDS_ROOM: {
        return 'toys';
      }
      case RoomType.TV_ROOM: {
        return 'tv';
      }
      case RoomType.BATHROOM: {
        return 'shower';
      }
      case RoomType.HALL: {
        return 'light';
      }
      case RoomType.BEDROOM: {
        return 'bed';
      }
      case RoomType.KITCHEN: {
        return 'blender';
      }
      case RoomType.OFFICE: {
        return 'local_printshop';
      }
      case RoomType.TOILET: {
        return 'wc';
      }
      case RoomType.MISC:
      default: {
        return 'door_back';
      }
    }
  }
}
