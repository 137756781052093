import { Injectable } from '@angular/core';
import { BehaviorSubject, filter, first, map } from 'rxjs';
import { ConsumptionTendencies, LatestConsumption, LatestConsumptionReading, Tendency } from '../models';
import { ConsumptionService } from './consumption.service';

enum Sign {
  MUCH_MUCH_MORE = '+>',
  MUCH_MUCH_LESS = '->',
  MUCH_MORE = '>',
  MORE = '+',
  LESS = '-',
  NONE = ''
}

enum TendencyPeriod {
  M2M = 'M2M',
  Y2Y = 'Y2Y',
}

@Injectable({
  providedIn: 'root'
})
export class ConsumptionTendencyService {
  public MAX_CHANGE_VALUE = 99.0;

  private tendencyEmitter = new BehaviorSubject<ConsumptionTendencies>(null);

  public constructor(
    private consumption: ConsumptionService
  ) { }

  public get tendency$() {
    return this.tendencyEmitter.asObservable();
  }

  public init(consumptions: LatestConsumption) {
    const tendency = this.tendencyEmitter.getValue() || {};

    Object.keys(consumptions).forEach(serviceKey => {
      tendency[serviceKey] = {};
      this.parseConsumption(tendency, serviceKey, consumptions[serviceKey]);
    });

    this.tendencyEmitter.next(tendency);
  }

  public getTendency(consumptionType: string) {
    return this.tendency$
      .pipe(
        filter(t => !!t),
        first(),
        map(tendency => Object.values(tendency[consumptionType.toUpperCase()]))
      );
  }

  private parseConsumption(tendency: ConsumptionTendencies, serviceKey: string, latestServiceConsumptions: LatestConsumptionReading[]) {
    latestServiceConsumptions.forEach(unitConsumption => {
      const values = this.consumption.convertDataPointsToSeriesData(unitConsumption.values);
      const lastMonthConsumption = values.at(-1);
      const toCompare = this.getConsumptionsToCompare(lastMonthConsumption, values);

      if (!toCompare.previousMonth && !toCompare.previousYear) {
        tendency[serviceKey][unitConsumption.unit] = {
          present: false,
          tendency: null,
          percent: null,
          unit: null,
          primary: null,
          type: null
        };
      }
      else {
        tendency[serviceKey][unitConsumption.unit] = {
          present: true,
          percent: this.computeChange(lastMonthConsumption.value, toCompare),
          type: toCompare.previousYear ? TendencyPeriod.Y2Y : TendencyPeriod.M2M,
          tendency: this.calculateTendency(lastMonthConsumption.value, toCompare),
          unit: unitConsumption.unit,
          primary: unitConsumption.primary
        };
      }
    });
  }

  private getConsumptionsToCompare(consumption: { date: Date; value: number }, consumptions: { date: Date; value: number }[]) {
    return {
      previousMonth: this.findByDate(consumptions, this.withAddedMonths(consumption.date, -1)),
      previousYear: this.findByDate(consumptions, this.withAddedMonths(consumption.date, -12)),
    };
  }

  private findByDate(consumptions, date) {
    return consumptions.find((item: any) => (
      item.date.getYear() === date.getYear() &&
      item.date.getMonth() === date.getMonth()
    ));
  }

  private withAddedMonths(base, months) {
    const result = new Date(base);
    result.setMonth(result.getMonth() + months);

    return result;
  }

  private computeChange(value: number, toCompare: { previousMonth: any; previousYear: any }) {
    const comparable = this.getComparable(toCompare);

    if (comparable === 0.0) {
      if (value === 0.0) {
        return '0';
      } else {
        return this.changeOf(Sign.MUCH_MUCH_MORE, this.MAX_CHANGE_VALUE);
      }
    }
    if (comparable > 0 && value === 0) {
      return this.changeOf(Sign.MUCH_MUCH_LESS, this.MAX_CHANGE_VALUE);
    }

    const change = parseInt('' + (((value - comparable) / comparable) * 100.0), 10);

    if (Math.abs(change) > this.MAX_CHANGE_VALUE) {
      return this.changeOf(Sign.MUCH_MORE, this.MAX_CHANGE_VALUE);
    }

    return this.signedValue(change);
  }

  private getComparable(toCompare: { previousMonth: any; previousYear: any }) {
    return toCompare.previousYear
      ? toCompare.previousYear.value
      : toCompare.previousMonth.value;
  }

  private changeOf(sign: string, change: number) {
    return sign + change;
  }

  private signedValue(value) {
    if (value > 0) {
      return this.changeOf(Sign.MORE, Math.abs(value));
    }

    return value === 0
      ? this.changeOf(Sign.NONE, value)
      : this.changeOf(Sign.LESS, Math.abs(value));
  }

  private calculateTendency(value: number, toCompare: { previousMonth: any; previousYear: any }) {
    const comparable = this.getComparable(toCompare);

    if (value === comparable) {
      return Tendency.EQUAL;
    }

    return value > comparable
      ? Tendency.UP
      : Tendency.DOWN;
  }
}
