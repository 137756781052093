import { Injectable } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie';
import { BehaviorSubject, firstValueFrom, map, Observable, shareReplay, take } from 'rxjs';
import * as moment from 'moment';
import localePL from '@angular/common/locales/pl';
import localeDE from '@angular/common/locales/de';
import localeIT from '@angular/common/locales/it';
import localeRO from '@angular/common/locales/ro';
import localeCS from '@angular/common/locales/cs';
import { TranslationLoadingService } from '@shared/localization';

const COOKIE_NAME = 'locale';

@Injectable({
  providedIn: 'root'
})
export class LanguageSelectionService {
  public allowedLocaleStrings = [
    'en-gb',
    'pl-pl',
    'it-it',
    'ro-ro',
  ];
  public defaultLocaleString = this.getValidLocaleString(
    this.translation.getBrowserCultureLang().toLowerCase(),
    this.translation.getDefaultLang()
  );

  public readonly locale$: Observable<any>;

  private readonly localeEmitter = new BehaviorSubject(null);

  public constructor(
    private translation: TranslateService,
    private translationLoading: TranslationLoadingService,
    private cookie: CookieService
  ) {
    registerLocaleData(localePL);
    registerLocaleData(localeDE);
    registerLocaleData(localeIT);
    registerLocaleData(localeRO);
    registerLocaleData(localeCS);

    this.locale$ = this.localeEmitter.asObservable();

    this.translation.setDefaultLang('en-gb');
    this.setLocale(this.cookie.get(COOKIE_NAME));
  }

  public get localeSnapshot(): { lang; country; localeString } {
    return this.localeEmitter.value;
  }

  public getTranslationWithoutSwitchingLanguage(lang: string): Observable<any> {
    return this.translation.currentLoader.getTranslation(lang).pipe(
      shareReplay(1),
      take(1),
      map((res: object) => this.translation.compiler.compileTranslations(res, lang)),
      shareReplay(1),
      take(1),
    );
  }

  public async setLocale(localeString: string) {
    const locale = this.getValidLocale(localeString);

    const translationResources = await firstValueFrom(
      this.translationLoading.getTranslation(locale.localeString)
    );
    this.translation.setTranslation(locale.localeString, translationResources);
    this.translation.use(locale.localeString);

    moment.locale(locale.lang);
    // this.materialDateAdapter.setLocale(locale.lang);

    this.localeEmitter.next(locale);

    if (this.cookie.get(COOKIE_NAME) !== locale.localeString) {
      this.cookie.put(COOKIE_NAME, locale.localeString, { expires: (10 * 360).toString(), path: '/' }); }
  }

  private getValidLocale(localeString: string) {
    const validLocaleString = this.getValidLocaleString(localeString, this.defaultLocaleString);

    return {
      lang: validLocaleString.substring(0, 2),
      country: validLocaleString.substring(3, 2),
      localeString: validLocaleString
    };
  }

  private getValidLocaleString(locale: string, fallbackLocale: string) {
    return this.isNotAllowedLocale(locale)
      ? fallbackLocale
      : locale;
  }

  private isNotAllowedLocale(locale: string) {
    return !this.allowedLocaleStrings.find(l => l === locale);
  }
}
