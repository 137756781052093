import { Injectable } from '@angular/core';
import { BehaviorSubject, filter, map, Observable } from 'rxjs';
import { ConsumptionType } from '@shared/consumption';
import { ConsumptionUnit, LatestConsumption } from '../models';

interface UnitManagerState { [consumptionType: string]: { default: ConsumptionUnit } }

interface UnitManagerOptions { [consumptionType: string]: { leading: ConsumptionUnit; additional: ConsumptionUnit } }

@Injectable({
  providedIn: 'root'
})
export class UnitManagerService {

  public readonly state$: Observable<UnitManagerState>;
  public readonly options$: Observable<UnitManagerOptions>;

  private readonly state = new BehaviorSubject<UnitManagerState>({});
  private readonly options = new BehaviorSubject<UnitManagerOptions>({});

  public constructor() {
    this.state$ = this.state.asObservable();
    this.options$ = this.options.asObservable();
  }

  public init(consumptions: LatestConsumption) {
    Object.keys(consumptions).forEach((key) => {
      const ofGivenType = consumptions[key];

      const leading = ofGivenType.find(c => c.primary);
      const additional = ofGivenType.find(c => !c.primary);

      const options = this.options.getValue();
      options[key] = {
        leading: leading?.unit,
        additional: additional?.unit
      };

      this.options.next(options);
      this.resolveDefault(ConsumptionType[key], leading?.unit);
    });
  }

  public handleUnitSwitch(type: ConsumptionType, unit: ConsumptionUnit) {
    const units = this.options.getValue()[type.toUpperCase()];
    const unitExist = units.leading === unit || units.additional === unit;

    if (unitExist) {
      this.setDefault(type, unit);
    }
    else {
      throw new Error('Passed unit doesn\'t exist!');
    }

    return this.getDefaultUnit(ConsumptionType[type.toUpperCase()]);
  }

  public getDefaultUnit(consumption: ConsumptionType) {
    return this.state$.pipe(
      filter(state => !!Object.keys(state).length),
      map(state => state[consumption.toUpperCase()]?.default)
    );
  }

  public getUnits(consumption: string) { // TODO use enum
    return this.options$.pipe(
      map(options => options[consumption.toUpperCase()]),
      filter(value => !!value)
    );
  }

  private resolveDefault(consumption: ConsumptionType, mainUnit: ConsumptionUnit) {
    const state = this.state.getValue();

    if (!!state[consumption.toUpperCase()]) {
      return;
    }

    this.setDefault(consumption, mainUnit);
  }

  private setDefault(consumption: ConsumptionType, mainUnit: ConsumptionUnit) {
    const state = this.state.getValue();
    state[consumption.toUpperCase()] = { default: mainUnit };

    this.state.next(state);
  }
}
