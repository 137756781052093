export const compareByField = <T>(fieldName: string) =>
  (t1: T, t2: T) => {
    if (t1[fieldName] === undefined || t1[fieldName] === null) {
      return 1;
    }

    if (t2[fieldName] === undefined || t2[fieldName] === null) {
      return -1;
    }

    const a = t1[fieldName].toString().toLowerCase();
    const b = t2[fieldName].toString().toLowerCase();

    if (a > b) { return 1; }
    if (a < b) { return -1; }

    return 0;
  };

// eslint-disable-next-line @typescript-eslint/ban-types
export const assign = <TObj extends {}>(destination: TObj, overwriteWith: Partial<TObj>) => {
  if (!overwriteWith) { return JSON.parse(JSON.stringify(destination)); }

  return Object.assign(
    JSON.parse(JSON.stringify(destination || {})),
    JSON.parse(JSON.stringify(overwriteWith || {}))
  ) as TObj;
};

export const absoluteCompare = (obj1: any, obj2: any) => JSON.stringify(obj1) === JSON.stringify(obj2);

export const subset = <TObj>(obj: TObj, keys: string[]) => keys.reduce((o: Partial<TObj>, k) => { o[k] = obj[k]; return o; }, {});
