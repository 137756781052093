import {ChangeDetectionStrategy, Component} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MatIcon } from '@angular/material/icon';
import { FuseAlertComponent } from '@ista-ui/angular';

@Component({
    selector: 'ic-password-policy-box',
    templateUrl: './password-policy-box.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [FuseAlertComponent, MatIcon, TranslateModule]
})
export class PasswordPolicyBoxComponent {
}
