import { formatCurrency } from '@angular/common';
import { CurrencySymbols } from '@shared/common';

export const currencySymbol = (amount, currencyCode, fraction: number = 0) => {
  if (fraction === undefined) {
    fraction = 2;
  }

  const symbol = CurrencySymbols[currencyCode];
  const noAmount = () => amount == null;

  return noAmount() ?
    (symbol || currencyCode) :
    formatCurrency(amount, symbol ? symbol : currencyCode, fraction.toString());
};
