import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { MatAnchor } from '@angular/material/button';
import { MatLabel } from '@angular/material/form-field';
import { MatToolbar } from '@angular/material/toolbar';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'ic-app-footer',
    template: `
    <mat-toolbar class="flex-col md:flex-row md:justify-end">
      <a mat-button [routerLink]="['/imprint']" >{{ "IMPRINT" | translate }}</a>
      <a mat-button [routerLink]="['/terms']" >{{ "TERMS" | translate }}</a>
      <a mat-button [routerLink]="['/contact']">{{ "CONTACT.TITLE" | translate }}</a>

      <mat-label *ngIf="isBranded" class="w-full text-center md:w-auto md:ml-4">
        <small>powered by &copy;ista connect</small>
      </mat-label>
    </mat-toolbar>
  `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [MatToolbar, MatAnchor, TranslateModule, RouterLink, NgIf, MatLabel]
})
export class AppFooterComponent {
  @HostBinding('class')
  public cssClass = '';

  @Input()
  public isBranded: boolean;
}
