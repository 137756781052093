import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { catchError, EMPTY, tap } from 'rxjs';
import { Notification, NotificationLiveTime, NotificationService } from '@shared/notifications';
import { Settings, SettingsResource } from '@shared/settings';
import { EmailApiService, RequestEmailChangePayload } from './email-api.service';

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  public constructor(
    private settings: SettingsResource,
    private resource: EmailApiService,
    private notifications: NotificationService
  ) { }

  public requestEmailChange(payload: RequestEmailChangePayload) {
    return this.resource
      .requestEmailChange(payload)
      .pipe(
        tap(() => {
          this.notifications.show(
            Notification.toast().asWarning({
              messageKey: 'CHANGE_EMAIL_SUCCESS',
              messageParams: { email: payload.newEmailAddress },
              liveTime: NotificationLiveTime.UNTIL_TAPPED
            })
          );
        }),
        catchError(error => {
          this.onEmailChangeRequestFailed(error);
          return EMPTY;
        })
      );
  }

  public updateAlertingEmailIfRequested(userId: number, emailForAlerts: string, settings: Settings) {
    const { phoneNumber } = settings.alertingEngineSettingsModel || {};

    return this.settings
      .update(userId, {
        trackMe: settings.trackMe,
        emailForAlerts,
        phoneNumberForAlerts: phoneNumber
      });
  }

  private onEmailChangeRequestFailed({ error }: HttpErrorResponse) {
    // default error message
    let messageKey = 'CHANGE_EMAIL_ERROR';

    const isWrongPassword = (errors: { [key: string]: { code; params }[] }) =>
      !!errors.currentPassword?.length &&
      errors.currentPassword[0].code === 'WRONG_PASSWORD';

    if (!!error?.errors && isWrongPassword(error.errors)) { messageKey = 'CHANGE_EMAIL_ERROR_CREDENTIALS'; }

    this.notifications.show(
      Notification.toast().asError({ messageKey })
    );
  }
}
