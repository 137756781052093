import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environment';

export interface RequestEmailChangePayload {
  currentPassword: string;
  newEmailAddress: string;
}

@Injectable({
  providedIn: 'root'
})
export class EmailApiService {

  public constructor(
    private http: HttpClient
  ) { }

  public requestEmailChange(payload: RequestEmailChangePayload) {
    return this.http
      .put(
        `${environment.restEndpoint}/requestEmailAddressChange`,
        payload
      );
  }

  public activateNewEmail(token: string) {
    return this.http
      .put(
        `${environment.restEndpoint}/confirmEmailAddressChange`,
        { token }
      );
  }
}

