import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { ChartData, ConsumptionUnit, SeriesData } from '../models';

@Injectable({ providedIn: 'root' })
export class ConsumptionChartDataService {

  public convertChartData(chartData: ChartData, goalUnit: ConsumptionUnit, convertFactor: number) {
    let noChanges = true;

    if (chartData.unit !== goalUnit) {
      chartData.dataPoints.forEach(dataPoint => {
        dataPoint.value = dataPoint.value * convertFactor;
      });
      noChanges = false;
    }
    if (chartData.averageUnit !== goalUnit) {
      chartData.averageDataPoints.forEach(averageDataPoint => {
        averageDataPoint.value = averageDataPoint.value * convertFactor;
      });
      noChanges = false;
    }
    if (noChanges) {
      return chartData;
    } else {
      return {
        dataPoints: chartData.dataPoints,
        intervals: chartData.intervals,
        unit: goalUnit,
        averageDataPoints: chartData.averageDataPoints,
        averageUnit: goalUnit,
        showAverage: chartData.showAverage,
      };
    }
  }

  public calculateFirstInterval(dataPoints: SeriesData<number>[]) {
    const min = dataPoints[0].date;
    const max = dataPoints[dataPoints.length - 1].date;

    const startDate = new Date(
      Math.max(min.getTime(), moment(max).subtract(11, 'months').toDate().getTime())
    );
    const monthsToShift = this.calculateMonthsToShift(min, max);

    return {
      startDate: moment(startDate).add(monthsToShift, 'months').toDate(),
      endDate: moment(startDate)
        .add(monthsToShift + 11, 'months')
        .toDate(),
    };
  }

  /**
   * If we have e.g. dataPoints that span 13 months, normally chart 1 would contain 12 dataPoints and chart 2 only 1 dataPoint.
   * We can not display a line with only 1 dataPoint, so we aim to display at least 3 dataPoints in every chart.
   * This function returns the number of months we need to shift the fixed intervals to have an optimal distribution of the dataPoints.
   */
  public calculateMonthsToShift(minDate: Date, maxDate: Date) {
    const amountOfMonths = moment(maxDate).diff(moment(minDate), 'months') + 1;
    const amountOfMonthsModulo = amountOfMonths % 12;

    if (
      amountOfMonths > 12 &&
      amountOfMonths < 36 &&
      amountOfMonthsModulo > 0 &&
      amountOfMonthsModulo < 3
    ) {
      return 3 - amountOfMonthsModulo;
    }

    return 0;
  }
}
