import { Route } from '@angular/router';
import { DenyAnonymousGuard, ensureTosAccepted, requireRole, UserRole } from './user';
import {
  ChangePasswordComponent
} from './change-password/components/change-password/change-password.component';
import {
  ActivateNewEmailComponent
} from './change-email/components/activate-new-email/activate-new-email.component';
import { ChangeEmailComponent } from './change-email/components/change-email/change-email.component';
import { UserActivationComponent } from './activation/components/user-activation/user-activation.component';

export const SECURITY_ROUTES: Route[] = [
  {
    path: 'logout',
    loadComponent: () => import('./logout/components/logout/logout.component').then(c => c.LogoutComponent)
  },
  {
    path: 'register',
    loadComponent: () => import('./registration/components/user-registration/user-registration.component').then(c => c.UserRegistrationComponent)
  },
  {
    path: 'password',
    data: { backLinkTarget: '/welcome' },
    canActivate: [DenyAnonymousGuard, ensureTosAccepted, requireRole(UserRole.PM)],
    component: ChangePasswordComponent
  },
  {
    path: 'email/token',
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: '/email'
      },
      {
        path: ':token',
        data: { backLinkTarget: '/welcome' },
        component: ActivateNewEmailComponent
      }
    ]
  },
  {
    path: 'email',
    data: { backLinkTarget: '/welcome' },
    canActivate: [DenyAnonymousGuard, ensureTosAccepted, requireRole(UserRole.PM)],
    component: ChangeEmailComponent
  },
  {
    path: 'userActivation/token/:token', // TODO: investigate why two different URLs for the same component
    data: { backLinkTarget: '/welcome' },
    component: UserActivationComponent
  },
  {
    path: 'activate/token/:token', // TODO: investigate why two different URLs for the same component
    data: { backLinkTarget: '/welcome' },
    component: UserActivationComponent
  }
];
