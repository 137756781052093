import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environment';
import { buildBypassHttpStatuses } from '@shared/common';
import { CurrentUser } from '../models';

@Injectable({ providedIn: 'root' })
export class CurrentUserApiService {

  public constructor(
    private http: HttpClient
  ) { }

  public fetchUser() {
    const headers = buildBypassHttpStatuses([401, 403]);

    return this.http
      .get<CurrentUser>(
        `${environment.restEndpoint}/currentUser`,
        { headers }
      );
  }
}
